/** @typedef {import('api/actions/cost-estimate-get-action/cost-estimate-get-action-response').CostEstimateGetActionResponse} CostEstimate */
/** @typedef {import("../data/CostEstimateData").CostEstimateData} CostEstimateData */

import useImmutable from 'hooks/use-immutable';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import createCostEstimateData from 'pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateData';
import { useParams } from 'react-router-dom';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { useClient } from 'providers/client/ClientProvider';
import createDiscountData from 'pages/finance/cost-estimate/add-cost-estimate/data/DiscountData';
import { createCostEstimateSectionData } from 'pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateSectionData';
import {
  CUSTOM_JOB_POSITION_ITEM_ID,
  createCostEstimateRowData,
} from 'pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData';
import createAgencyFeeData from 'pages/finance/cost-estimate/add-cost-estimate/data/AgencyFeeData';
import deepEqual from 'deep-equal';
import { _t } from 'lang';
import deepDiff from 'deep-diff';
import { OBJECT_DOES_NOT_EXIST_ERROR_CODE } from 'utils/constants';
import { createCostEstimatePartData } from '../data/CostEstimatePartData';
import { usePriceList } from 'providers/price-list/PriceListProvider';

/** @type {CostEstimate} */
const emptyCostEstimate = undefined;

/**
 * The data context for the AddCostEstimate page.
 *
 * @type {React.Context<{
 *   data: CostEstimateData,
 *   initialData: CostEstimateData,
 *   updateData: (update: Partial<CostEstimateData>|((data: CostEstimateData) => Partial<CostEstimateData>)) => void,
 *   hasChanges: () => boolean,
 *   costEstimate?: CostEstimate,
 * }>}
 */
export const DataContext = createContext();

/**
 * Provides the data context to the AddCostEstimate page.
 *
 * @param {{
 *   children: React.ReactNode,
 * }}
 */
export function DataProvider({ children }) {
  const { getAction, fullName } = useApi();
  const { setClientId } = useClient();
  const { setPriceListId } = usePriceList();
  const { costEstimateId } = useParams();
  const [costEstimate, setCostEstimate] = useState(emptyCostEstimate);
  const [loading, setLoading] = useState(!!costEstimateId);

  const [initialData, updateInitialData] = useImmutable(
    () => ({
      costEstimateId: costEstimateId ? Number(costEstimateId) : null,
      createdBy: fullName,
      updatedBy: fullName,
      collections: [
        {
          sections: [{ isInHouse: true, rows: [{ isInHouse: true }, { isInHouse: true }, { isInHouse: true }] }],
          projects: [],
        },
      ],
    }),
    { createFn: createCostEstimateData }
  );

  const [data, updateData] = useImmutable(initialData, { createFn: createCostEstimateData });

  useEffect(() => {
    if (costEstimateId) {
      const costEstimateGetAction = getAction('CostEstimateGetAction');

      costEstimateGetAction({ parameters: { cost_estimate_id: costEstimateId } })
        .then((data) => {
          setCostEstimate(data);
          setClientId(data.client.client_id);
          setPriceListId(data.price_list_id);

          const collections = data.collections.map((collection) =>
            createCostEstimatePartData({
              projects: collection.projects.map((project) => ({
                projectId: project.project_id,
                name: project.name,
                allTasks: project.all_tasks,
                tasks: project.tasks,
              })),
              sections: collection.sections.map((section) =>
                createCostEstimateSectionData({
                  isInHouse: section.is_in_house,
                  name: section.section_name,
                  discount: section.discount
                    ? createDiscountData({
                        amount: section.discount,
                        type: section.discount_unit.trim() === '%' ? 'relative' : 'absolute',
                        comment: section.discount_comment || '',
                      })
                    : undefined,
                  agencyFee: section.agency_fee
                    ? createAgencyFeeData({
                        amount: section.agency_fee,
                        comment: section.agency_fee_comment || '',
                      })
                    : undefined,
                  rows: section.items.map((item) =>
                    createCostEstimateRowData({
                      isInHouse: section.is_in_house,
                      jobPositionId: item.position_in_company_id || CUSTOM_JOB_POSITION_ITEM_ID,
                      jobPositionName: item.position_in_company_id ? item.title : _t('Custom'),
                      note: item.title,
                      number: item.number,
                      unit: item.unit,
                      unitPrice: item.unit_price,
                      comment: item.comment || '',
                      externalCosts: item.external_cost,
                    })
                  ),
                })
              ),
            })
          );

          const update = {
            costEstimateId: data.cost_estimate_id,
            createdAt: new Date(data.created_at),
            createdBy: data.creator.full_name,
            updatedBy: data.updater?.full_name ?? undefined,
            commentThreadId: data.comment_thread_id,
            name: data.cost_estimate_name,
            prefix: data.cost_estimate_prefix,
            currency: data.currency,
            description: data.description,
            note: data.note,
            isDraft: data.is_draft,
            discount: data.discount
              ? createDiscountData({
                  amount: data.discount,
                  type: data.discount_unit.trim() === '%' ? 'relative' : 'absolute',
                  comment: data.discount_comment || '',
                })
              : undefined,
            collections,
          };

          updateData(update);
          updateInitialData(update);
        })
        .catch((e) => {
          if (e.code === OBJECT_DOES_NOT_EXIST_ERROR_CODE) {
            updateData({ costEstimateId: null });
          } else {
            panic(e);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [costEstimateId]);

  /**
   * Determines whether the data has changed.
   */
  const hasChanges = () => {
    const hasChanges = !deepEqual(data, initialData);

    if (hasChanges) {
      const diff = deepDiff(initialData, data);
      console.log(diff); // eslint-disable-line no-console
    }

    return hasChanges;
  };

  const value = useMemo(
    () => ({ data, initialData, costEstimate, updateData, hasChanges }),
    [data, initialData, costEstimate, updateData, hasChanges]
  );

  return <DataContext.Provider value={value}>{loading ? <></> : children}</DataContext.Provider>;
}

/**
 * Uses the data context.
 */
export function useData() {
  return useContext(DataContext);
}
