/** @typedef {import("pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData").CostEstimateRowData} CostEstimateRowData */

import { Select, Skeleton } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useJobSelect } from 'pages/finance/cost-estimate/add-cost-estimate/providers/JobSelectProvider';

/**
 * Input inside a CostEstimateRow to specify a job position.
 *
 * @param {{
 *   data: CostEstimateRowData,
 *   updateData: (value: Partial<CostEstimateRowData>) => void,
 * }}
 */
export default function JobPosition({ data, updateData }) {
  const [jobPositionId, setJobPositionId] = useState(data.jobPositionId);
  const { jobData } = useJobSelect();

  // Propagate changes with debounce.
  useEffect(() => {
    if (jobData !== null) {
      const jobPositionName = jobData.find((job) => job.value === jobPositionId)?.label ?? '';

      updateData({
        jobPositionId,
        jobPositionName,
      });
    }
  }, [jobData, jobPositionId]);

  if (jobData === null) {
    return <Skeleton w="100%" h={36} radius={8} />;
  }

  return <Select data={jobData} value={jobPositionId} onChange={setJobPositionId} searchable />;
}
