import JobPositionForm from 'components/forms/job-position-form/JobPositionForm';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { JOB_POSITIONS_PAGE_PATH } from 'routes/paths';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import panic from 'errors/Panic';

/**
 * Add Job Position page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=508%3A26263
 */
export default function AddJobPositionPage() {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { refreshJobPositions } = useSettingsSidebar();

  const initialValues = {
    positionName: '',
    projectBasedRate: '0',
    flatFeeRate: '0',
    internalCost: '',
    isForSale: true,
    members: [],
  };

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ positionName, projectBasedRate, flatFeeRate, internalCost, isForSale, members }) => {
    const positionInCompanyCreateAction = getAction('PositionInCompanyCreateAction');

    return positionInCompanyCreateAction({
      body: {
        position_name: positionName,
        project_based_rate: projectBasedRate,
        flat_fee_rate: flatFeeRate,
        members: members,
        internal_cost: internalCost,
        is_for_sale: isForSale,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Job position created'),
          message: _t("Job position '%s' has been created successfully.", positionName),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        refreshJobPositions();

        navigate(JOB_POSITIONS_PAGE_PATH.original);
      })
      .catch(panic);
  };

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <PageHeading heading={_t('Add new job position')} />
        <JobPositionForm initialValues={initialValues} onSubmit={submit} />
      </div>
    </DashboardLayout>
  );
}
