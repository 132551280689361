/** @typedef {import("components/comments/data/types").ICommentFormDataOut} ICommentFormDataOut */
/** @typedef {import("components/comments/data/types").IComment} IComment */

import { Box, Collapse, Group, Stack } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import CommentAdapter from 'components/comments/CommentAdapter';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import sleep from 'utils/sleep';
import { CommentDataProvider, useCommentData } from 'components/comments/providers/CommentDataProvider';
import CommentFormPlaceholder from 'components/comments/form/CommentFormPlaceholder';
import Avatar from 'components/avatars/Avatar';
import { nanoid } from 'nanoid';
import CommentCreateForm from 'components/comments/form/CommentCreateForm';
import { COMMENT_REACTION_TYPES } from 'components/comments/data/reactions';
import CommentReply from 'components/comments/CommentReply';
import CommentEditForm from 'components/comments/form/CommentEditForm';
import { noop, uniq } from 'lodash';
import GroupHeadline from 'components/GroupHeadline';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import { _t } from 'lang';
import CommentThreadNotifyMeSwitch from './CommentThreadNotifyMeSwitch';
import { COMMENT_FOCUS_SCROLL_BEHAVIOR, COMMENT_OPEN_FORM_SCROLL_BEHAVIOR } from 'environment';
import CommentLoader from './CommentLoader';

/**
 * @typedef {{
 *   groupHeadlineKey: string;
 *   permissionSlug: string;
 *   withHeadline: boolean;
 *   headlineCollapsible: boolean;
 *   headlineInitialOpened: boolean;
 *   withNotifyMeSwitch: boolean;
 *   isSticky?: boolean;
 *   formToggleTransitionDuration?: number;
 *   formFocusDelay?: number;
 *   anchorOffset?: number;
 *   onCommentAdded?: (comment: IComment) => void;
 *   onCommentEdited?: (commentId: string) => void;
 *   onCommentDeleted?: (commentId: string) => void;
 * }} CommentThreadProps
 */

/**
 * Displays a comment thread.
 *
 * @param {CommentThreadProps} props
 */
function CommentThreadImpl({
  groupHeadlineKey,
  permissionSlug,
  withHeadline = true,
  headlineCollapsible = false,
  headlineInitialOpened = true,
  withNotifyMeSwitch = true,
  isSticky = false,
  formToggleTransitionDuration = 350,
  formFocusDelay = 100,
  anchorOffset = 250,
  onCommentAdded = noop,
  onCommentEdited = noop,
  onCommentDeleted = noop,
} = {}) {
  const anchorRef = useRef();
  const contentRef = useRef();
  const { fullName: currentUserFullName, userId: currentUserId, getAction } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const { threadId, comments, threadNotified, loading, fetchComments, removeComment, getComment } = useCommentData();
  const [beingDeleted, setBeingDeleted] = useState(null);
  const [replyingTo, setReplyingTo] = useState(null);
  const [editingComment, setEditingComment] = useState(undefined);
  const commentCreateFormRef = useRef(null);
  const commentEditFormRef = useRef(null);

  const [{ focusedComment, focusToken, focusScrollBehavior }, setFocus] = useState(() => ({
    focusedComment: null,
    focusToken: null,
    focusScrollBehavior: 'smooth',
  }));

  const [form, setForm] = useState(() => ({
    which: 'placeholder',
    keys: {
      placeholder: nanoid(),
      create: nanoid(),
      edit: nanoid(),
    },
  }));

  const [opened, { toggle }] = useLocalStorageDisclosure(groupHeadlineKey, headlineInitialOpened);

  const notify = useMemo(() => {
    if (replyingTo) {
      return replyingTo.notify;
    }

    return (threadNotified || [])
      .filter(({ user_id }) => user_id !== currentUserId)
      .map(({ user_id }) => String(user_id));
  }, [replyingTo, threadNotified, currentUserId]);

  /**
   * Opens the specified form.
   *
   * @param {'placeholder' | 'create' | 'edit'} which
   */
  const openForm = (which) => {
    setForm(({ keys }) => ({ which, keys: { ...keys, [which]: nanoid() } }));

    if (which === 'placeholder') {
      setReplyingTo(null);
    } else {
      anchorRef.current?.scrollIntoView({ behavior: COMMENT_OPEN_FORM_SCROLL_BEHAVIOR, block: 'start' });
    }
  };

  /**
   * Sets the focused comment and generates a new focus token.
   *
   * @param {string} commentId
   */
  const setFocusedComment = (commentId) =>
    setFocus({ focusedComment: commentId, focusToken: Date.now(), focusScrollBehavior: COMMENT_FOCUS_SCROLL_BEHAVIOR });

  /**
   * Opens a create form while replying to the specified comment.
   *
   * @param {string} commentId
   */
  const replyToComment = (commentId) => {
    try {
      const comment = getComment(commentId);

      if (!comment) {
        throw new Error(`Comment ${commentId} not found`);
      }

      const notify = [comment.author.userId]
        .concat(comment.notified || [])
        .filter((id) => id !== currentUserId)
        .map(String);

      setReplyingTo({
        commentId: comment.commentId,
        author: comment.author,
        excerpt: comment.excerpt,
        notify: uniq(notify),
      });

      openForm('create');
    } catch (error) {
      panic(error);
    }
  };

  /**
   * Opens an edit form for the specified comment.
   *
   * @param {string} commentId
   */
  const startEditingComment = (commentId) => {
    try {
      const comment = getComment(commentId);

      if (!comment) {
        throw new Error(`Comment ${commentId} not found`);
      }

      setEditingComment(comment);
      setReplyingTo(comment.reply || null);
      openForm('edit');
    } catch (error) {
      panic(error);
    }
  };

  /**
   * Deletes the specified comment.
   *
   * @param {string} commentId
   */
  const deleteComment = (commentId) => {
    const commentDeleteAction = getAction('CommentDeleteAction');

    setBeingDeleted(commentId);

    // Wait for at least 600ms to make the UI feel more responsive.
    Promise.all([commentDeleteAction({ parameters: { comment_id: commentId } }), sleep(600)])
      .then(() => {
        removeComment(commentId);
        onCommentDeleted(commentId);
      })
      .catch(panic)
      .finally(() => setBeingDeleted(null));
  };

  /**
   * Creates a new comment.
   *
   * @type {(data: ICommentFormDataOut) => Promise<void>}
   */
  const createComment = async ({ attachments, notify, mentioned, text }) => {
    try {
      const commentCreateAction = getAction('CommentCreateAction');

      const { comment_id: commentId, excerpt } = await commentCreateAction({
        parameters: { comment_thread_id: threadId },
        body: {
          text,
          attachments,
          mentioned,
          notify: notify.map(Number),
          reply_to: replyingTo?.commentId,
        },
      });

      const comment = {
        commentId,
        text,
        attachments: attachments.map((fileId) => ({ fileId })),
        author: { fullName: currentUserFullName, userId: currentUserId },
        createdAt: new Date(),
        excerpt,
        notifiedPeopleCount: notify.length,
        reactions: COMMENT_REACTION_TYPES.map((type) => ({ type, count: 0, active: false })),
        reply: replyingTo,
        notified: notify.map(Number),
      };

      await fetchComments();

      onCommentAdded(comment);

      openForm('placeholder');
    } catch (error) {
      panic(error);
    }
  };

  /**
   * Edits an existing comment.
   *
   * @type {(data: ICommentFormDataOut) => Promise<void>}
   */
  const editComment = async ({ attachments, notify, mentioned, text }) => {
    try {
      const commentUpdateAction = getAction('CommentUpdateAction');

      await commentUpdateAction({
        parameters: { comment_thread_id: threadId, comment_id: editingComment.commentId },
        body: {
          text,
          attachments,
          mentioned,
          notify: notify.map(Number),
        },
      });

      await fetchComments();

      onCommentEdited(editingComment.commentId);

      openForm('placeholder');
    } catch (error) {
      panic(error);
    }
  };

  /**
   * Removes the comment search parameter from the URL.
   */
  const removeCommentSearchParam = useCallback(
    (param) =>
      setSearchParams(
        (curr) => {
          curr.delete(param);
          return curr;
        },
        { replace: true }
      ),
    [setSearchParams]
  );

  // Focus comment if it's specified in URL
  useEffect(() => {
    const comment = searchParams.get('c');

    if (comment) {
      setFocusedComment(comment);
    }
  }, [searchParams]);

  // Open form if it's specified in URL
  useEffect(() => {
    const replyCommentId = searchParams.get('r');

    if (!loading && replyCommentId) {
      replyToComment(replyCommentId);
      removeCommentSearchParam('r');
    }
  }, [loading, searchParams]);

  // Remove comment search parameter when the focused comment changes.
  useEffect(() => {
    const comment = getComment(focusedComment);

    if (comment) {
      removeCommentSearchParam();
    }
  }, [getComment, focusedComment]);

  // Focus just opened form.
  useEffect(() => {
    if (form.which === 'create') {
      setTimeout(() => commentCreateFormRef.current?.focus(), formFocusDelay);
    }

    if (form.which === 'edit') {
      setTimeout(() => commentEditFormRef.current?.focus(), formFocusDelay);
    }
  }, [form]);

  const contentPosition = isSticky && form.which === 'placeholder' ? 'md:sticky top-[188px] z-[90]' : '';
  const headlinePosition = isSticky ? 'md:sticky top-[120px] z-[90]' : '';

  return (
    <Stack spacing={16}>
      {withHeadline && (
        <GroupHeadline
          heading={_t('COMMENTS')}
          opened={opened}
          setOpened={toggle}
          number={comments.length}
          loadingNumber={loading}
          className={`bg-neutral-50 ${headlinePosition}`}
          actions={
            <Group position="right">
              {withNotifyMeSwitch && <CommentThreadNotifyMeSwitch threadId={threadId} loading={loading} />}
            </Group>
          }
          collapsible={headlineCollapsible}
        />
      )}
      <Collapse in={opened || !headlineCollapsible}>
        <Stack spacing={8}>
          <div ref={anchorRef} className="h-0 w-0" style={{ transform: `translateY(-${anchorOffset}px)` }}></div>
          <div ref={contentRef} className={`${contentPosition} grid grid-cols-[40px_1fr] gap-x-4 bg-neutral-50 pb-4`}>
            <Avatar size={40} label={currentUserFullName} />
            <Box>
              <Collapse in={form.which === 'placeholder'} transitionDuration={formToggleTransitionDuration}>
                <CommentFormPlaceholder
                  key={form.keys.placeholder}
                  onClick={() => openForm('create')}
                  visible={form.which === 'placeholder'}
                />
              </Collapse>
              <Collapse in={form.which === 'create'} transitionDuration={formToggleTransitionDuration}>
                <Stack key={form.keys.create} spacing={16}>
                  {replyingTo && (
                    <CommentReply {...replyingTo} onClick={() => setFocusedComment(replyingTo.commentId)} />
                  )}
                  <CommentCreateForm
                    key={form.keys.create}
                    commentCreateFormRef={commentCreateFormRef}
                    onClose={() => openForm('placeholder')}
                    onSubmit={createComment}
                    notify={notify}
                    permissionSlug={permissionSlug}
                  />
                </Stack>
              </Collapse>
              <Collapse in={form.which === 'edit'} transitionDuration={formToggleTransitionDuration}>
                {editingComment && (
                  <Stack key={form.keys.edit} spacing={16}>
                    {replyingTo && (
                      <CommentReply {...replyingTo} onClick={() => setFocusedComment(replyingTo.commentId)} />
                    )}
                    <CommentEditForm
                      key={form.keys.edit}
                      commentEditFormRef={commentEditFormRef}
                      comment={editingComment}
                      onClose={() => openForm('placeholder')}
                      onSubmit={editComment}
                      permissionSlug={permissionSlug}
                    />
                  </Stack>
                )}
              </Collapse>
            </Box>
          </div>
          {loading ? (
            <Stack mt={24} spacing={32}>
              <CommentLoader />
              <CommentLoader />
              <CommentLoader />
            </Stack>
          ) : (
            comments.map((comment) => (
              <CommentAdapter
                key={comment.commentId}
                comment={comment}
                beingDeleted={comment.commentId === beingDeleted}
                hasFocus={comment.commentId === focusedComment}
                focusToken={focusToken}
                focusScrollBehavior={focusScrollBehavior}
                anchorOffset={anchorOffset - (form.which === 'placeholder' ? 0 : 56)}
                onFocus={setFocusedComment}
                onDelete={deleteComment}
                onReply={replyToComment}
                onEdit={startEditingComment}
              />
            ))
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
}

/**
 * Displays a comment thread.
 *
 * @typedef {{
 *   threadId: string;
 *   autoRefreshEnabled?: boolean;
 * }} CommentDataProviderProps
 *
 * @param {CommentThreadProps & CommentDataProviderProps} props
 */
export default function CommentThread({ threadId, autoRefreshEnabled = false, ...rest }) {
  return (
    <CommentDataProvider threadId={threadId} autoRefreshEnabled={autoRefreshEnabled}>
      <CommentThreadImpl {...rest} />
    </CommentDataProvider>
  );
}
