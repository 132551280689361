/** @typedef {import('pages/finance/cost-estimate/add-cost-estimate/data/AssociatedProjectData').AssociatedProjectData} AssociatedProjectData */

import { Box, Collapse, Group, Menu, Stack, Switch, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useApi } from 'api/ApiContext';
import CollapseArrow from 'components/CollapseArrow';
import panic from 'errors/Panic';
import useImmutable from 'hooks/use-immutable';
import { _t } from 'lang';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createAssociatedProjectData } from 'pages/finance/cost-estimate/add-cost-estimate/data/AssociatedProjectData';
import AssociatedTask from './AssociatedTask';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import { IconUnlink } from '@tabler/icons';
import ProjectIcon from '../../../../../../components/icons/ProjectIcon';
import { Link } from 'react-router-dom';
import { PROJECT_DETAIL_PAGE_PATH } from '../../../../../../routes/paths';
import { useData } from '../../providers/DataProvider';
import { useCostEstimatePartData } from '../part/CostEstimatePart';

/** @type {import('api/actions/project-get-structure-action/project-get-structure-action-response').ProjectGetStructureActionResponse} */
const emptyProject = null;

/**
 * A single project associated with the cost estimate collection.
 *
 * @param {{
 *   initialData: AssociatedProjectData,
 *   onChange: (data: AssociatedProjectData) => void,
 *   onDelete: () => void,
 * }}
 */
export default function AssociatedProject({ initialData, onChange, onDelete }) {
  const { getAction } = useApi();
  const { confirm } = useConfirm();
  const [opened, { toggle: toggleOpened }] = useDisclosure(true);
  const [project, setProject] = useState(emptyProject);
  const [data, updateData] = useImmutable(initialData, { createFn: createAssociatedProjectData });

  const {
    data: { costEstimateId, updatedAt, collections },
  } = useData();

  const {
    data: { id: collectionId },
  } = useCostEstimatePartData();

  const isFreeToAssociate = useMemo(() => {
    if (!project) {
      return false;
    }

    for (const task of project.tasks) {
      if (task.cost_estimate_id && task.cost_estimate_id !== costEstimateId) {
        return false;
      }

      if (task.subtasks) {
        for (const subtask of task.subtasks) {
          if (subtask.cost_estimate_id && subtask.cost_estimate_id !== costEstimateId) {
            return false;
          }
        }
      }
    }

    return collections
      .filter(({ id }) => id !== collectionId)
      .every(({ projects }) =>
        projects
          .filter(({ projectId }) => projectId === data.projectId)
          .every(({ allTasks, tasks }) => !allTasks && tasks.length === 0)
      );
  }, [project, costEstimateId, collectionId, collections, data.projectId]);

  const confirmDelete = useCallback(
    () =>
      confirm({
        title: _t('Unassign project'),
        message: _t('Are you sure you want to unassign this project?'),
        primaryButtonText: _t('Unassign'),
        onConfirm: onDelete,
      }),
    [confirm, onDelete]
  );

  useEffect(() => {
    const getProject = getAction('ProjectGetStructureAction');

    getProject({ parameters: { project_id: data.projectId } })
      .then(setProject)
      .catch(panic);

    // Refresh project data when the cost estimate is updated.
  }, [data.projectId, updatedAt]);

  // Propagate data.
  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <Stack spacing={0} className="group/associated-project">
      <Group position="apart" align="center" pl={40} pr={8}>
        <Group h={56} align="center" spacing={4} onClick={toggleOpened} className="cursor-pointer">
          <Box w={24} h={24}>
            <CollapseArrow opened={opened} />
          </Box>
          <Text fz={18}>{data.name}</Text>
        </Group>
        <Menu>
          <Menu.Target>
            <Box className="h-6 w-6 cursor-pointer opacity-0 transition-opacity group-hover/associated-project:opacity-100">
              <OptionsDotsIcon />
            </Box>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<ProjectIcon />}>
              <Link to={PROJECT_DETAIL_PAGE_PATH.insert({ projectId: data.projectId })} target={'_blank'}>
                <span>{_t('View project')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item onClick={confirmDelete} icon={<IconUnlink />}>
              <span>{_t('Unlink project')}</span>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      {project && (
        <Collapse in={opened}>
          <Group h={48} align="center" pl={40} pr={8}>
            <Switch
              label={_t('Include all existing and future task and subtasks')}
              disabled={!isFreeToAssociate}
              checked={data.allTasks}
              onClick={() => updateData(({ allTasks }) => ({ allTasks: !allTasks }))}
            />
          </Group>
          {project.tasks
            .filter(({ deleted_at, minutes_total }) => !deleted_at || minutes_total > 0)
            .map((task) => (
              <AssociatedTask key={task.task_id} task={task} data={data} updateData={updateData} />
            ))}
        </Collapse>
      )}
    </Stack>
  );
}
