/** @typedef {import('pages/finance/cost-estimate/add-cost-estimate/data/AssociatedProjectData').AssociatedProjectData} AssociatedProjectData */

import { Box, Button, Divider, Group } from '@mantine/core';
import ProjectSelect from 'components/selects/ProjectSelect';
import { _t } from 'lang';
import { nanoid } from 'nanoid';
import { useClient } from 'providers/client/ClientProvider';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import AssociatedProject from './AssociatedProject';
import { useData } from '../../providers/DataProvider';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { useCostEstimatePartData } from '../part/CostEstimatePart';

/**
 * List of projects associated with the cost estimate collection.
 */
export default function AssociatedProjectsList() {
  const { getAction } = useApi();
  const { data, updateData } = useCostEstimatePartData();
  const { data: costEstimateData } = useData();
  const { clientId } = useClient();
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [projects, setProjects] = useState(() => data.projects.map(createProject));
  const [assigningProject, setAssigningProject] = useState(false);

  const takenProjectIds = useMemo(
    () =>
      costEstimateData.collections.flatMap(({ projects }) =>
        projects.filter(({ allTasks }) => allTasks).map(({ projectId }) => projectId)
      ),
    [costEstimateData.collections]
  );

  /**
   * Handles project change.
   *
   * @param {string} id
   * @param {AssociatedProjectData} data
   */
  function onProjectChange(id, data) {
    setProjects((projects) => projects.map((project) => (project.id === id ? { ...project, data } : project)));
  }

  /**
   * Deletes a project.
   *
   * @param {string} id
   */
  function deleteProject(id) {
    setProjects((projects) => projects.filter((project) => project.id !== id));
  }

  /**
   * Creates an empty project.
   *
   * @param {AssociatedProjectData} [data]
   */
  function createProject(data) {
    const id = nanoid();

    const component = (
      <AssociatedProject
        key={id}
        initialData={data}
        onChange={(data) => onProjectChange(id, data)}
        onDelete={() => deleteProject(id)}
      />
    );

    return { id, component };
  }

  /**
   * Adds a project.
   *
   * @param {AssociatedProjectData} [data]
   */
  function addProject(data) {
    const newProject = createProject(data);
    setProjects((currProjects) => [...currProjects, newProject]);
  }

  /**
   * Adds the selected project to the list of associated projects.
   */
  function selectProject() {
    if (assigningProject) {
      return;
    }

    const getProject = getAction('ProjectGetAction');

    setAssigningProject(true);

    getProject({ parameters: { project_id: selectedProjectId } })
      .then(({ project_full_name, is_free_to_associate }) => {
        addProject({
          projectId: Number(selectedProjectId),
          name: project_full_name,
          allTasks: is_free_to_associate,
        });

        setSelectedProjectId(null);
      })
      .catch(panic)
      .finally(() => setAssigningProject(null));
  }

  const selectProjectFilters = useMemo(
    () => ({
      'client_id.eq': clientId,
      'project_id.not_in': data.projects.map(({ projectId }) => projectId),
    }),
    [clientId, data.projects]
  );

  const isItemDisabled = useCallback(
    (item) => takenProjectIds.includes(item.project_id) || item.cost_estimate_collection_id,
    [takenProjectIds]
  );

  // Compute data.
  useEffect(() => {
    updateData({
      projects: projects.map(({ data }) => data).filter(Boolean),
    });
  }, [projects]);

  return (
    <Box>
      {projects.map(({ id, component }) => (
        <Fragment key={id}>
          {component}
          <Divider color="neutral100" mt={-1} pt={1} />
        </Fragment>
      ))}
      {data.projects.length === 0 && (
        <Box px={40}>
          {_t('Select tasks and subtasks that will contribute to internal costs for this cost estimate.')}
        </Box>
      )}
      <Group spacing={8} pt={16} px={40}>
        <ProjectSelect
          w={320}
          value={selectedProjectId}
          onChange={setSelectedProjectId}
          actionFilter={selectProjectFilters}
          placeholder={_t('Associate Project with Cost Estimate')}
          isItemDisabled={isItemDisabled}
        />
        <Button variant="primary" onClick={selectProject} loading={assigningProject} disabled={!selectedProjectId}>
          {_t('Assign project')}
        </Button>
      </Group>
    </Box>
  );
}
