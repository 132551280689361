import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import ClientForm from 'components/forms/client-form/ClientForm';
import { _t } from 'lang';
import { useNavigate } from 'react-router-dom';
import { CLIENTS_PAGE_PATH } from 'routes/paths';
import {
  BAD_REQUEST_ERROR_CODE,
  DUPLICATE_CLIENT_SHORTCUT_SLUG_ERROR_CODE,
  SUCCESS_NOTIFICATION_COLOR,
} from 'utils/constants';
import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import panic from 'errors/Panic';
import { useState } from 'react';

/**
 * Displays a form to add a client.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=522%3A24642
 */
export default function AddClientPage() {
  const navigate = useNavigate();
  const { getAction, workspaceConfig } = useApi();
  const { refreshClients } = useSettingsSidebar();

  const [hasShortcutSlugError, setHasShortcutSlugError] = useState(false);
  const [hasNextProjectNumError, setHasNextProjectNumError] = useState(false);

  const initialValues = {
    name: '',
    officialName: '',
    ico: '',
    dic: '',
    icDph: '',
    streetAndNumber: '',
    city: '',
    zipCode: '',
    country: '',
    avatar: '',
    contactPerson: [],
    language: 'sk',
    priceLists: [],
    currency: workspaceConfig.currency,
    increasingPrice: 0,
    addPoToInvoice: false,
    note: '',
    noteCostEstimate: '',
    noteInvoice: '',
    members: [],
    shortcutSlug: '',
    shortcutInternal: '',
    nextProjectNum: 1,
    maxProjectNum: 0,
  };

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({
    name,
    officialName,
    ico,
    dic,
    icDph,
    streetAndNumber,
    city,
    zipCode,
    country,
    avatar,
    contactPerson,
    language,
    priceLists,
    currency,
    increasingPrice,
    addPoToInvoice,
    note,
    noteCostEstimate,
    noteInvoice,
    members,
    shortcutSlug,
    shortcutInternal,
    nextProjectNum,
  }) => {
    const clientCreateAction = getAction('ClientCreateAction');

    return clientCreateAction({
      body: {
        client_name: name,
        official_name: officialName,
        ico,
        dic,
        ic_dph: icDph,
        street_and_number: streetAndNumber,
        city: city,
        zip_code: zipCode,
        country: country,
        avatar,
        contact_persons: contactPerson.map(({ name, email, phone }) => ({ full_name: name, email, phone })),
        language,
        price_lists: priceLists.map(Number),
        increasing_price: increasingPrice,
        add_po_to_invoice: addPoToInvoice,
        currency,
        note,
        note_cost_estimate: noteCostEstimate,
        note_invoice: noteInvoice,
        members,
        shortcut_slug: shortcutSlug,
        shortcut_internal: shortcutInternal,
        next_project_num: nextProjectNum,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Client created'),
          message: _t("Client '%s' has been created successfully.", name),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        refreshClients();
        setHasShortcutSlugError(false);

        navigate(CLIENTS_PAGE_PATH.original);
      })
      .catch((e) => {
        if (e.code === DUPLICATE_CLIENT_SHORTCUT_SLUG_ERROR_CODE) {
          setHasShortcutSlugError(true);
        } else if (e.code === BAD_REQUEST_ERROR_CODE) {
          // this should never happen - unless the user somehow bypasses the frontend validation
          setHasNextProjectNumError(true);
        } else {
          panic(e);

          navigate(CLIENTS_PAGE_PATH.original);
        }
      });
  };

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <PageHeading heading={_t('Add new client')} />
        <ClientForm
          initialValues={initialValues}
          onSubmit={submit}
          hasShortcutSlugError={hasShortcutSlugError}
          hasNextProjectNumError={hasNextProjectNumError}
        />
      </div>
    </DashboardLayout>
  );
}
