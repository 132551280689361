import { Button, Group, Stack, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import AddIcon from 'components/icons/AddIcon';
import NotifiedIcon from 'components/icons/NotifiedIcon';
import UserTagSelect from 'components/selects/UserTagSelect';
import { _t } from 'lang';
import { noop } from 'lodash';
import { useEffect } from 'react';

/**
 * Used to display and modify the list of users that will be notified when
 * a comment is posted.
 *
 * @param {{
 *   value?: string[];
 *   onChange?: (value: string[]) => void;
 *   initialOpened?: boolean
 *   loading?: boolean;
 *   placeholderCount?: number;
 *   clientId?: number;
 *   permissionSlug?: string;
 * }}
 */
export default function CommentNotifiedPeople({
  value = [],
  onChange = noop,
  initialOpened = false,
  loading = false,
  placeholderCount = 0,
  clientId,
  permissionSlug,
} = {}) {
  const theme = useMantineTheme();
  const [opened, { open }] = useDisclosure(initialOpened);

  const count = loading ? placeholderCount : value.length;

  // Open the form if there are already users to be notified.
  useEffect(() => {
    if (value.length > 0) {
      open();
    }
  }, [open, value.length]);

  return (
    <Stack spacing={8} pt={16}>
      <div className="flex h-6 items-center gap-2">
        <div className="text-[15px] font-medium leading-[18px] text-neutral-700">{_t('Notify')}</div>
        <div className="flex items-center gap-1">
          <NotifiedIcon stroke={theme.colors.neutral700[0]} width={16} height={16} />
          <div className="text-[12px] leading-[16px]">{count || _t('No users will be notified')}</div>
        </div>
      </div>
      {opened ? (
        <UserTagSelect
          value={value}
          onChange={onChange}
          placeholder={_t('Also notify ...')}
          autoFocus={!initialOpened && value.length === 0} // Only autofocus if the form is opened for the first time.
          loading={loading}
          placeholderCount={placeholderCount}
          clientId={clientId}
          permissionSlug={permissionSlug}
        />
      ) : (
        <Group>
          <Button variant="link" leftIcon={<AddIcon />} onClick={open}>
            {_t('user')}
          </Button>
        </Group>
      )}
    </Stack>
  );
}
