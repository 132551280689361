import { useData } from '../../providers/DataProvider';
import { useMemo } from 'react';
import CommentThread from 'components/comments/CommentThread';

/**
 * Comments Section in Add Cost Estimate Page.
 */
export default function CommentsSection() {
  const { data } = useData();

  const headlineKey = useMemo(
    () => `toolio.cost-estimate.${data.costEstimateId}.draft.comments.opened`,
    [data.costEstimateId]
  );

  if (!data.commentThreadId) {
    return null;
  }

  return (
    <CommentThread
      threadId={data.commentThreadId}
      withHeadline
      headlineCollapsible
      groupHeadlineKey={headlineKey}
      permissionSlug="FINANCE_MANAGE_FINANCE"
    />
  );
}
