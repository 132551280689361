import { MultiSelect, Skeleton, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useApi } from 'api/ApiContext';
import CloseIcon from 'components/icons/CloseIcon';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { noop, times } from 'lodash';
import { nanoid } from 'nanoid';
import { useEffect, useMemo, useState } from 'react';

/**
 * Styles of the Mantine MultiSelect component.
 */
const styles = {
  input: {
    height: '36px',
    padding: '0 0 0 8px',
    cursor: 'text',
  },
  values: {
    minHeight: 0,
  },
  value: {
    display: 'none',
  },
};

/**
 * Used to select multiple users. Displays the selected users as tags.
 *
 * @param {{
 *   value?: string[];
 *   onChange?: (value: string[]) => void;
 *   placeholder?: string;
 *   autoFocus?: boolean;
 *   clientId?: number;
 *   permissionSlug?: string,
 *   loading?: boolean;
 *   placeholderCount?: number;
 *   fullWidth?: boolean;
 *   readOnly?: boolean;
 *   disabled?: boolean;
 *   listPosition?: 'top' | 'bottom';
 * }}
 */
export default function UserTagSelect({
  value = [],
  onChange = noop,
  placeholder,
  autoFocus,
  clientId,
  permissionSlug,
  loading = false,
  placeholderCount = 0,
  fullWidth = false,
  readOnly = false,
  disabled = false,
  listPosition = 'top',
}) {
  const { getAction } = useApi();
  const [uuid] = useState(nanoid);
  const [loadingUsers, { close: stopLoading }] = useDisclosure(true);
  const [searchValue, setSearchValue] = useState('');
  const [users, setUsers] = useState([]);
  const [usersMap, setUsersMap] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);

  const data = useMemo(
    () => (loadingUsers ? [{ value: '', label: _t('Loading ...'), disabled: true }] : users),
    [loadingUsers, users]
  );

  const dropdownPosition = useMemo(() => (listPosition === 'top' ? 'bottom' : 'top'), [listPosition]);
  const listOrder = useMemo(() => ({ order: listPosition === 'top' ? 1 : 2 }), [listPosition]);
  const selectOrder = useMemo(() => ({ order: listPosition === 'top' ? 2 : 1 }), [listPosition]);

  // Fetch users.
  useEffect(() => {
    const userGetListAction = getAction('UserGetListAction');

    const filter = { 'is_active.eq': 1 };

    if (clientId && permissionSlug) {
      filter['permission.has'] = [{ client_id: clientId, permission_slug: permissionSlug }];
    }

    userGetListAction({ query: { filter } })
      .then((data) => {
        const users = [];
        const usersMap = {};

        data
          .sort((a, b) => a.full_name.localeCompare(b.full_name))
          .map(({ full_name: fullName, user_id: userId }) => ({
            label: fullName,
            value: String(userId),
          }))
          .forEach((user, order) => {
            users.push(user);
            usersMap[user.value] = { ...user, order };
          });

        setUsers(users);
        setUsersMap(usersMap);

        onChange(value.filter((id) => usersMap[id]));
      })
      .catch(panic)
      .finally(stopLoading);
  }, [clientId, permissionSlug]);

  // Update selected users.
  useEffect(() => {
    setSelectedUsers(value.map((id) => usersMap[id]).filter(Boolean));
  }, [value, usersMap]);

  const placeholderVisible = !searchValue && placeholder ? '' : 'hidden';

  return (
    <Stack spacing={16}>
      <div className="flex flex-wrap gap-2 empty:hidden" style={listOrder}>
        {loadingUsers || loading
          ? times(placeholderCount, (i) => <Skeleton key={i} w={100 + ((i * 37) % 59)} h={24} radius={8} />)
          : selectedUsers.map(({ value: userId, label: fullName, order }) => (
              <div key={userId} style={{ order }} className="flex h-6 items-center rounded-[8px] bg-neutral-100 pl-2">
                <div className="whitespace-nowrap text-[15px] leading-[20px] text-neutral-700">{fullName}</div>
                <div
                  className="flex h-6 w-6 cursor-pointer items-center justify-center"
                  onClick={() => onChange(value.filter((id) => id !== userId))}
                >
                  <CloseIcon width={16} height={16} />
                </div>
              </div>
            ))}
      </div>
      <div className={`relative ${fullWidth ? 'w-full' : 'w-[278px]'}`} style={selectOrder}>
        <label
          htmlFor={uuid}
          className={`absolute left-[10px] top-2 z-10 cursor-text select-none text-[15px] leading-[20px] text-neutral-300 ${placeholderVisible}`}
        >
          {placeholder}
        </label>
        <MultiSelect
          id={uuid}
          data={data}
          value={value}
          onChange={onChange}
          styles={styles}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          searchable
          autoFocus={autoFocus}
          readOnly={readOnly}
          disabled={disabled}
          dropdownPosition={dropdownPosition}
        />
      </div>
    </Stack>
  );
}
