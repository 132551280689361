/** @typedef {import("components/comments/data/types").ICommentReply} ICommentReply */
/** @typedef {import("components/comments/data/types").ICommentFormDataOut} ICommentFormDataOut */

import { Button, Group, Stack } from '@mantine/core';
import UploadFiles from 'components/files/upload/UploadFiles';
import { _t } from 'lang';
import { useCallback, useEffect, useRef } from 'react';
import CommentNotifiedPeople from 'components/comments/form/CommentNotifiedPeople';
import WysiwygEditor from 'components/inputs/wysiwyg/WysiwygEditor';
import { noop } from 'lodash';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import useLoadingAction from 'hooks/use-loading-action';
import useImmutable from 'hooks/use-immutable';
import { useCommentData } from '../providers/CommentDataProvider';
import striptags from 'striptags';
import sleep from 'utils/sleep';

const WYSIWYG_DEBOUNCE_WAIT = 500;

/**
 * A form to add a new comment.
 *
 * @typedef {React.Ref<{
 *   focus: () => void;
 * }>} CommentCreateFormRef
 *
 * @param {{
 *   commentCreateFormRef?: CommentCreateFormRef;
 *   permissionSlug?: string;
 *   autofocus?: boolean | 'start' | 'end' | 'all' | number | null
 *   onSubmit?: (data: ICommentFormDataOut) => Promise<void> | void;
 *   onClose?: () => void;
 * }}
 */
export default function CommentCreateForm({
  commentCreateFormRef,
  permissionSlug,
  notify = [],
  autofocus = false,
  onSubmit = noop,
  onClose = noop,
} = {}) {
  const { clientId, cache, setCache, resetCache } = useCommentData();
  const { confirm } = useConfirm();
  const editorRef = useRef(null);

  const [data, updateData] = useImmutable(() => ({
    notify: cache?.notify ?? notify,
    attachments: cache?.attachments ?? [],
    text: cache?.text ?? '',
  }));

  const onTextChange = useCallback((text) => updateData({ text }), [updateData]);

  const submitImpl = useCallback(
    async (data) => {
      await onSubmit(data);

      // Cache is set on text change which is debounced so we reset it twice to
      // make sure it's reset even if the last debounce happened after the submit.
      resetCache();
      await sleep(WYSIWYG_DEBOUNCE_WAIT);
      resetCache();
    },
    [onSubmit, resetCache]
  );

  const [loading, submit] = useLoadingAction(submitImpl);

  /**
   * Confirms closing the comment form.
   */
  const confirmClose = useCallback(
    () =>
      confirm({
        title: _t('Discard comment'),
        message: _t('Are you sure you want to discard this comment? The text will not be saved.'),
        onConfirm: () => {
          onClose();
          resetCache();
        },
      }),
    [confirm, onClose, resetCache]
  );

  /**
   * Handles a user being mentioned.
   *
   * @param {number} userId - The ID of the mentioned user.
   */
  const onUserMentioned = (userId) => {
    if (!data.notify.includes(String(userId))) {
      updateData({ notify: [...data.notify, String(userId)] });
    }
  };

  /**
   * Handles the primary action.
   */
  const onPrimaryAction = (e) => {
    e.preventDefault();

    submit({
      notify: data.notify,
      attachments: (data.attachments || []).map(({ fileId }) => fileId),
      text: editorRef.current?.getHTML(),
      mentioned: editorRef.current?.getMentioned(),
    });
  };

  useEffect(() => {
    if (commentCreateFormRef) {
      commentCreateFormRef.current = {
        focus: () => editorRef.current?.focus(),
      };
    }

    return () => {
      if (commentCreateFormRef) {
        commentCreateFormRef.current = null;
      }
    };
  }, [commentCreateFormRef]);

  useEffect(() => {
    const strippedText = striptags(data.text, ['img']);

    if (strippedText.length > 0 || data.attachments?.length > 0) {
      setCache(data);
    }
  }, [data, setCache]);

  return (
    <form onSubmit={onPrimaryAction}>
      <Stack spacing={16}>
        <WysiwygEditor
          editorRef={editorRef}
          onUserMentioned={onUserMentioned}
          autofocus={autofocus}
          clientId={clientId}
          permissionSlug={permissionSlug}
          onChange={onTextChange}
          initialContent={data.text}
          debounceWait={WYSIWYG_DEBOUNCE_WAIT}
        />
        <UploadFiles initialFiles={data.attachments} onChange={(attachments) => updateData({ attachments })} />
        <CommentNotifiedPeople
          value={data.notify}
          onChange={(notify) => updateData({ notify })}
          clientId={clientId}
          permissionSlug={permissionSlug}
        />
        <Group position="right" spacing={16} py={16}>
          <Button h={36} w={80} variant="link" type="button" disabled={loading} onClick={confirmClose}>
            {_t('Cancel')}
          </Button>
          <Button variant="primary" type="submit" loading={loading}>
            {_t('Comment')}
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
