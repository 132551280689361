/** @typedef {Array<{ label: string, value: number|null }>} JobData */

import { _t } from 'lang';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CUSTOM_JOB_POSITION_ITEM_ID } from 'pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData';
import { sortBy } from 'lodash';
import { usePriceList } from 'providers/price-list/PriceListProvider';

/**
 * The job select context for the AddCostEstimate page.
 *
 * @type {React.Context<{
 *   jobData: JobData | null;
 *   hasJobPosition: (jobPositionId: number) => boolean;
 * }>}
 */
const JobSelectContext = createContext();

/**
 * Provides the JobSelect context to the AddCostEstimate page.
 *
 * @param {{
 *   children: React.ReactNode,
 * }}
 */
export function JobSelectProvider({ children }) {
  const [jobData, setJobData] = useState(null);
  const { priceList } = usePriceList();

  // Set data for the job select on price list change.
  useEffect(() => {
    const data = [];

    // Create a data for the select.
    if (priceList?.price_list_items) {
      const priceListItems = sortBy(priceList.price_list_items, 'position_in_company_name');

      priceListItems
        .filter(({ is_for_sale }) => is_for_sale)
        .forEach((item) =>
          data.push({
            label: item.position_in_company_name,
            value: item.position_in_company_id,
          })
        );
    }

    // Add a custom option.
    data.push({
      label: _t('Custom'),
      value: CUSTOM_JOB_POSITION_ITEM_ID,
    });

    setJobData(data);
  }, [priceList]);

  const hasJobPosition = useCallback(
    (jobPositionId) => jobData?.some(({ value }) => value === jobPositionId),
    [jobData]
  );

  const value = useMemo(() => ({ jobData, hasJobPosition }), [jobData, hasJobPosition]);

  return <JobSelectContext.Provider value={value}>{children}</JobSelectContext.Provider>;
}

/**
 * Uses the JobSelect context.
 */
export function useJobSelect() {
  return useContext(JobSelectContext);
}
