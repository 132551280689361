import { useForm } from '@mantine/form';
import FormRow from 'components/forms/FormRow';
import { _t } from 'lang';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import { Stack, Switch, Tabs, TextInput } from '@mantine/core';
import UserSelect from 'components/selects/UserSelect';
import MoneyInput from 'components/inputs/MoneyInput';
import { useSearchParams } from 'react-router-dom';
import useLoadingAction from 'hooks/use-loading-action';
import FormFooter from '../FormFooter';
import SectionTitle from '../FormSectionTitle';
import Alert from 'components/Alert';

/**
 * @typedef {{
 *   positionName: string;
 *   hourRate: number;
 *   members: string[];
 * }} JobPositionFormData
 */

/**
 * The Add / Edit form for Job Positions.
 *
 * @param {{
 *   initialValues: JobPositionFormData,
 *   onSubmit: (values: JobPositionFormData) => void | Promise<void>,
 * }}
 */
export default function JobPositionForm({ initialValues, onSubmit }) {
  const [loading, submit] = useLoadingAction(onSubmit);
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || 'job-position';

  const form = useForm({
    initialValues,

    validate: {
      positionName: createValidator([required]),
      projectBasedRate: createValidator([required]),
      flatFeeRate: createValidator([required]),
    },
  });

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Tabs defaultValue={activeTab}>
        <Tabs.List mx={-32} px={32}>
          <Tabs.Tab value="job-position">{_t('Job Position')}</Tabs.Tab>
          <Tabs.Tab value="members">{_t('Members')}</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="job-position">
          <Stack spacing={16} pt={32}>
            <Alert
              severity="information"
              primaryText={
                // prettier-ignore
                _t("Disabling a job position's availability for cost estimates excludes it from all client price lists.")
              }
            />
            <FormRow
              label={_t('Job position name')}
              input={
                <TextInput
                  type="text"
                  placeholder={_t('Job position name')}
                  autoFocus
                  {...form.getInputProps('positionName')}
                />
              }
            />
            <FormRow
              label={_t('Position available for use in cost estimate')}
              input={<Switch {...form.getInputProps('isForSale', { type: 'checkbox' })} />}
            />
            <SectionTitle title={_t('Hour rates')} />
            <FormRow
              label={_t('Project-based (default) hour rate')}
              input={
                <MoneyInput
                  currency="EUR"
                  min={0}
                  disabled={!form.values.isForSale}
                  value={form.values.isForSale ? form.values.projectBasedRate : 0}
                  onChange={(value) => form.setFieldValue('projectBasedRate', value)}
                />
              }
            />
            <FormRow
              label={_t('Internal hour rate')}
              input={<MoneyInput currency="EUR" min={0} {...form.getInputProps('internalCost')} />}
            />
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value="members">
          <Stack spacing={16} pt={16}>
            <FormRow
              label={_t('Members (readonly)')}
              tooltipText={_t('The job position of a member can be changed in the user profile.')}
              input={
                <UserSelect
                  readOnly
                  disabled
                  multiple={true}
                  placeholder={_t('No members')}
                  {...form.getInputProps('members')}
                />
              }
            />
          </Stack>
        </Tabs.Panel>
      </Tabs>

      <FormFooter loading={loading} skipCancelConfirmation={() => !form.isDirty()} />
    </form>
  );
}
