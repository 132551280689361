/** @typedef {import('./CostEstimateSectionData').CostEstimateSectionData} CostEstimateSectionData */
/** @typedef {import('./AssociatedProjectData').AssociatedProjectData} AssociatedProjectData */

import { round, sumBy } from 'lodash';
import { nanoid } from 'nanoid';

/**
 * Creates a new instance of CostEstimatePartData.
 *
 * @param {{
 *   id?: string,
 *   sections: CostEstimateSectionData[],
 *   projects: AssociatedProjectData[],
 * }}
 */
export function createCostEstimatePartData({ id = nanoid(), sections = [], projects = [] } = {}) {
  // Price after discount. Cost Estimate Part cannot have a discount therefore
  // there is no need to compute the subtotal.
  const total = round(sumBy(sections, 'total'), 2);

  // Sum of external costs.
  const externalCosts = round(sumBy(sections, 'externalCosts'), 2);

  // Sum of internal costs.
  const internalCosts = round(sumBy(sections, 'internalCosts'), 2);

  // Sum of all discounts.
  const totalDiscounts = sumBy(sections, 'discountAmount');

  // Sum of all agency fees.
  const totalAgencyFees = sumBy(sections, 'agencyFeeAmount');

  return Object.freeze({
    id,
    sections,
    projects,
    total,
    externalCosts,
    internalCosts,
    totalDiscounts,
    totalAgencyFees,
  });
}

/** @typedef {ReturnType<typeof createCostEstimatePartData>} CostEstimatePartData */
