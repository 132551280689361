/** @typedef {import('./CostEstimatePartData').CostEstimatePartData} CostEstimatePartData */
/** @typedef {import('./DiscountData').DiscountData} DiscountData */

import { round, sumBy } from 'lodash';

/**
 * Creates a new instance of CostEstimateData.
 *
 * @param {{
 *   costEstimateId: number,
 *   createdAt: Date,
 *   createdBy: string,
 *   updatedAt: Date,
 *   updatedBy: string,
 *   commentThreadId: string | null,
 *   name: string,
 *   description: string,
 *   note: string,
 *   collections: CostEstimatePartData[],
 *   discount: DiscountData,
 *   isDraft: boolean,
 *   currency: string,
 * }}
 */
export default function createCostEstimateData({
  costEstimateId,
  createdAt = new Date(),
  createdBy = '',
  updatedAt = new Date(),
  updatedBy = '',
  commentThreadId = null,
  name = 'Untitled cost estimate',
  prefix = '',
  description = '',
  note = '',
  collections,
  discount,
  isDraft = true,
  currency = 'EUR',
} = {}) {
  const fullName = `${prefix} ${name}`.trim();

  // Price before discount.
  const subtotal = round(sumBy(collections, 'total'), 2);

  // Price after discount.
  const total = discount ? discount.apply(subtotal) : subtotal;

  // Price after discount minus price before discount.
  const discountAmount = round(subtotal - total, 2);

  // Sum of external costs.
  const externalCosts = round(sumBy(collections, 'externalCosts'), 2);

  // Sum of internal costs.
  const internalCosts = round(sumBy(collections, 'internalCosts'), 2);

  // Total costs.
  const totalCosts = externalCosts + internalCosts;

  // Sum of all discounts.
  const totalDiscounts = sumBy(collections, 'totalDiscounts') + discountAmount;

  // Sum of all agency fees.
  const totalAgencyFees = sumBy(collections, 'totalAgencyFees');

  // Difference between total price and total costs.
  const profit = total - totalCosts;

  // Profit margin.
  const profitMargin = total > 0 ? profit / total : 0;

  return Object.freeze({
    costEstimateId,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    commentThreadId,
    name,
    prefix,
    fullName,
    description,
    note,
    collections,
    discount,
    subtotal,
    total,
    discountAmount,
    externalCosts,
    internalCosts,
    totalCosts,
    totalDiscounts,
    totalAgencyFees,
    profit,
    profitMargin,
    isDraft,
    currency,
  });
}

/** @typedef {ReturnType<typeof createCostEstimateData>} CostEstimateData */
