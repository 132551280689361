/** @typedef {import('api/actions/cost-estimate-get-list-action/cost-estimate-get-list-action-response').CostEstimateGetListActionResponse[number]} CostEstimate */
/** @typedef {import('components/files/upload/types').IFileState} IFileState */

import InputWrapper from 'components/inputs/InputWrapper';
import { _t } from 'lang';
import SupplierSelect from 'components/selects/SupplierSelect';
import CurrencySelect from 'components/selects/CurrencySelect';
import { Box, Button, Group, Modal, Stack, Text, TextInput, Textarea, Tooltip } from '@mantine/core';
import Alert from 'components/Alert';
import PlusIcon from 'components/icons/PlusIcon';
import { useForm } from '@mantine/form';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import formatPrice from 'utils/format-price';
import { ADD_SUPPLIER_PAGE_PATH } from 'routes/paths';
import MoneyInput from 'components/inputs/MoneyInput';
import FormRow from 'components/forms/FormRow';
import LinkWithConfirm from 'components/navigate/LinkWithConfirm';
import UploadFiles from 'components/files/upload/UploadFiles';
import { useDisclosure } from '@mantine/hooks';
import FormFooter from 'components/forms/FormFooter';
import { useEffect, useState } from 'react';
import { useSupplier } from 'hooks/data/use-supplier';
import useLoadingAction from 'hooks/use-loading-action';
import { isEmpty, noop } from 'lodash';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import DatePicker from 'components/inputs/DatePicker';

/**
 * The Add/ Edit External Costs form.
 *
 * @param {{
 *   initialValues: object,
 *   onSubmit: (values: object) => void | Promise<void>,
 *   costEstimate: CostEstimate,
 *   initialFiles: IFileState[],
 * }}
 */
export default function ExternalCostsForm({ initialValues, onSubmit, costEstimate, initialFiles = [] }) {
  const [loading, submit] = useLoadingAction(onSubmit);
  const [confirm, { open: openConfirm, close: closeConfirm }] = useDisclosure(false);
  const { setSupplierId, supplier } = useSupplier();
  const { workspaceConfig, getAction } = useApi();
  const [rates, setRates] = useState([]);

  const form = useForm({
    initialValues,

    validate: {
      supplierId: createValidator([required]),
      fileId: createValidator([required]),
      invoiceNumber: createValidator([required]),
      invoiceDate: createValidator([required]),
      supplyDate: createValidator([required]),
      paymentDueDate: createValidator([required]),
      currency: createValidator([required]),
      totalPaidAmountWithoutVat: createValidator([required]),
      invoiceSubject: createValidator([required]),
    },
  });

  const amount = form.values.baseAmount;
  const budget = costEstimate.external_cost_sum_estimated - costEstimate.external_cost_sum_actual;
  const amountOverflow = budget - amount + initialValues.baseAmount;

  const hasAmountOverflow = amountOverflow < 0;

  useEffect(() => {
    const getLatestRate = getAction('ExchangeRateGetLatestAction');
    getLatestRate()
      .then(({ success, rates }) => {
        if (success) {
          setRates(rates);
        }
      })
      .catch(panic);
  }, [getAction]);

  useEffect(() => {
    if (form.values.supplierId) {
      setSupplierId(form.values.supplierId);
    }
  }, [form.values.supplierId]);

  useEffect(() => {
    if (form.values.totalPaidAmountWithoutVat && form.values.currency) {
      if (form.values.currency !== workspaceConfig.currency && !isEmpty(rates)) {
        const rate = rates.find(
          (rate) => rate.base_currency === workspaceConfig.currency && rate.quote_currency === form.values.currency
        );

        if (rate) {
          form.setFieldValue('calculatedExpense', form.values.totalPaidAmountWithoutVat / rate.rate);
          form.setFieldValue('baseAmount', form.values.totalPaidAmountWithoutVat / rate.rate);
          form.setFieldValue('currencyRateDate', new Date(rate.valid_from));
          form.setFieldValue('currencyRate', rate.rate);
        }
      } else {
        form.setFieldValue('calculatedExpense', form.values.totalPaidAmountWithoutVat);
        form.setFieldValue('baseAmount', form.values.totalPaidAmountWithoutVat);
        form.setFieldValue('currencyRateDate', new Date());
        form.setFieldValue('currencyRate', 1);
      }
    }
  }, [form.values.totalPaidAmountWithoutVat, form.values.currency, rates]);

  return (
    <>
      <form onSubmit={form.onSubmit(openConfirm)}>
        {hasAmountOverflow && (
          <Alert
            className="mb-8 mt-6"
            severity="error"
            primaryText={_t(
              'The entered cost of %s exceeds the budgeted costs of %s by %s.',
              formatPrice(amount, costEstimate.currency),
              formatPrice(budget, costEstimate.currency),
              formatPrice(-amountOverflow, costEstimate.currency)
            )}
          />
        )}

        <Stack spacing={16}>
          <FormRow
            label={_t('Supplier')}
            input={
              <SupplierSelect
                placeholder={_t('Choose a supplier')}
                {...form.getInputProps('supplierId')}
                className="w-[500px]"
              />
            }
            leftAction={
              <Tooltip withArrow label={_t('Add supplier')}>
                <Box>
                  <LinkWithConfirm
                    confirmMessage={
                      _t('You will be redirected to the supplier creation page. The data you have inputted will be lost. Are you sure you want to continue?') // prettier-ignore
                    }
                    confirmTitle={_t('Add supplier')}
                    to={ADD_SUPPLIER_PAGE_PATH.original}
                  >
                    <PlusIcon stroke="#38298B" />
                  </LinkWithConfirm>
                </Box>
              </Tooltip>
            }
          />
          <FormRow
            label={_t('Invoice')}
            input={<UploadFiles maxFiles={1} {...form.getInputProps('fileId')} initialFiles={initialFiles} />}
          />
          <FormRow
            label={_t('Invoice number')}
            input={<TextInput type="text" {...form.getInputProps('invoiceNumber')} />}
          />
          <FormRow label={_t('Invoice date')} input={<DatePicker {...form.getInputProps('invoiceDate')} />} />
          <FormRow label={_t('Date of supply')} input={<DatePicker {...form.getInputProps('supplyDate')} />} />
          <FormRow label={_t('Payment due date')} input={<DatePicker {...form.getInputProps('paymentDueDate')} />} />
          <FormRow
            label={_t('Currency')}
            input={<CurrencySelect placeholder={_t('EUR')} {...form.getInputProps('currency')} />}
          />
          <FormRow
            label={_t('Total paid amount without VAT')}
            input={
              <MoneyInput
                min={0}
                currency={form.values.currency}
                {...form.getInputProps('totalPaidAmountWithoutVat')}
              />
            }
          />
          <FormRow
            label={_t('Calculated expense')}
            input={
              <MoneyInput
                min={0}
                currency={workspaceConfig.currency}
                readOnly
                disabled
                {...form.getInputProps('calculatedExpense')}
              />
            }
          />
          <FormRow
            label={_t('Actual amount paid')}
            input={<MoneyInput min={0} currency={workspaceConfig.currency} {...form.getInputProps('baseAmount')} />}
          />
          <FormRow
            label={_t('Invoice subject')}
            input={<Textarea placeholder={_t('Your comment')} minRows={5} {...form.getInputProps('invoiceSubject')} />}
          />
        </Stack>
        <InputWrapper
          label={_t('Internal note')}
          align="column"
          inputSize="full"
          input={<Textarea placeholder={_t('Enter note')} minRows={5} {...form.getInputProps('internalNote')} />}
          className="my-[32px]"
        />
        <FormFooter skipCancelConfirmation={() => !form.isDirty()} />
      </form>

      <Modal
        opened={confirm}
        onClose={loading ? noop : closeConfirm}
        centered
        padding={16}
        title={_t('Validate external invoice')}
        withCloseButton={!loading}
      >
        <Stack spacing={8}>
          <Text fz={15} lh={20 / 15}>
            <span
              dangerouslySetInnerHTML={{
                __html:_t('You´re about to insert a supplier´s invoice for <b>%s</b> that will be sent for payment.', form.values.invoiceSubject), // prettier-ignore
              }}
            />
            <br />
            <br />
            {_t('Supplier:')} <b>{supplier?.supplier_name}</b>
            <br />
            {_t('Invoice number:')} <b>{form.values.invoiceNumber}</b>
            <br />
            {_t('Amount:')} <b>{formatPrice(form.values.totalPaidAmountWithoutVat, form.values.currency)}</b>
            <br />
            {_t('The date of supply:')} <b>{form.values.supplyDate?.toLocaleDateString()}</b>
            <br />
            <br />
            {_t('Cost estimate:')} <b>{costEstimate.cost_estimate_full_name}</b>
          </Text>
          <Text fz={15} lh={20 / 15} pt={32} pb={16}>
            {
              _t('I confirm that attached invoice is correct, service from the supplier was fully provided and invoice should be paid.') // prettier-ignore
            }
          </Text>
          <Group position="right" pt={8} pb={16}>
            <Button disabled={loading} onClick={closeConfirm} variant="link" w={76} h={36}>
              {_t('Back')}
            </Button>
            <Button loading={loading} onClick={form.onSubmit(submit)} variant="primary">
              {_t('Continue')}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
}
