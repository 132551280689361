/** @typedef {import('pages/finance/cost-estimate/add-cost-estimate/data/AssociatedProjectData').AssociatedProjectData} AssociatedProjectData */
/** @typedef {import('api/actions/project-get-structure-action/project-get-structure-action-response').ProjectGetStructureActionResponse['tasks'][number]} Task */

import { useApi } from 'api/ApiContext';
import { useEffect, useMemo, useState } from 'react';
import { useData } from '../../providers/DataProvider';
import panic from 'errors/Panic';
import { Box, Checkbox, Group, Menu, Skeleton, Stack } from '@mantine/core';
import TaskStatusBubble from 'pages/projects/project-overview/project-table/task-table/TaskStatusBubble';
import PriceDisplay from 'components/PriceDisplay';
import OptionsDotsIcon from '../../../../../../components/icons/OptionsDotsIcon';
import ProjectIcon from '../../../../../../components/icons/ProjectIcon';
import { Link } from 'react-router-dom';
import { TASK_DETAIL_PAGE_PATH } from '../../../../../../routes/paths';
import { _t } from '../../../../../../lang';
import LockIcon from '../../../../../../components/icons/LockIcon';
import LockedIcon from '../../../../../../components/icons/LockedIcon';
import Truncate from 'components/Truncate';
import { useCostEstimatePartData } from '../part/CostEstimatePart';

/**
 * A single task associated with the cost estimate collection.
 *
 * @param {{
 *   task: Task,
 *   data: AssociatedProjectData,
 *   updateData: (data: AssociatedProjectData|((data: AssociatedProjectData) => Partial<AssociatedProjectData>)) => void,
 *   inner?: boolean,
 * }}
 */
export default function AssociatedTask({ task, data, updateData, inner = false }) {
  const { getAction } = useApi();
  const [internalCosts, setInternalCosts] = useState(null);

  const {
    data: { id: collectionId },
  } = useCostEstimatePartData();

  const {
    data: { collections, costEstimateId, currency, updatedAt },
  } = useData();

  const checked = useMemo(
    () => data.allTasks || data.tasks.includes(task.task_id),
    [data.allTasks, data.tasks, task.task_id]
  );

  const isInOtherCostEstimate = useMemo(
    () => !!task.cost_estimate_id && task.cost_estimate_id !== costEstimateId,
    [task.cost_estimate_id, costEstimateId]
  );

  const isInOtherCollection = useMemo(
    () =>
      collections
        .filter(({ id }) => id !== collectionId)
        .some(({ projects }) =>
          projects
            .filter(({ projectId }) => projectId === data.projectId)
            .some(({ allTasks, tasks }) => allTasks || tasks.includes(task.task_id))
        ),
    [task.task_id, collectionId, collections, data.projectId]
  );

  const disabled = useMemo(
    () => data.allTasks || isInOtherCostEstimate || isInOtherCollection,
    [data.allTasks, isInOtherCostEstimate, isInOtherCollection]
  );

  /**
   * Toggle task inclusion.
   */
  function toggleTask(taskId) {
    updateData(({ tasks }) =>
      tasks.includes(taskId) ? { tasks: tasks.filter((id) => id !== taskId) } : { tasks: [...tasks, taskId] }
    );
  }

  useEffect(() => {
    const getLogs = getAction('TimeLogGetListAction');

    if (isInOtherCostEstimate) {
      setInternalCosts(null);
    } else {
      getLogs({ query: { group_by: 'task_full_name', filter: { 'task_id.eq': task.task_id, 'is_billable.eq': 1 } } })
        .then(({ groups }) => setInternalCosts(groups[0]?.cost_total ?? 0))
        .catch(panic);
    }

    // Refresh project data when the cost estimate is updated.
  }, [task.task_id, isInOtherCostEstimate, updatedAt]);

  return (
    <Stack spacing={0}>
      <Group
        h={48}
        align="center"
        className="grid grid-cols-[1fr_360px_112px_140px] items-center gap-4 border-y border-y-transparent hover:border-y-neutral-100 hover:bg-neutral-20"
        pl={40 + (inner ? 24 : 0)}
        pr={40}
      >
        <Checkbox
          label={task.task_full_name}
          disabled={disabled}
          checked={checked}
          onChange={() => toggleTask(task.task_id)}
        />
        <Box /> {/* empty cell */}
        {isInOtherCostEstimate ? <Box></Box> : <TaskStatusBubble task={task} variant="small" />}
        <Group position="right">
          {isInOtherCostEstimate ? (
            <Box className="max-w-full rounded-lg bg-neutral-100 px-2 text-[12px]">
              <Truncate text={task.cost_estimate_full_name ?? _t('Error: cost estimate not found')} />
            </Box>
          ) : internalCosts === null ? (
            <Skeleton w={100} h={18} radius={4} />
          ) : (
            <PriceDisplay value={internalCosts} currency={currency} className={checked ? '' : 'text-neutral-300'} />
          )}
          <Menu position="right">
            <Menu.Target>
              <Box className="hidden h-6 w-6 cursor-pointer opacity-0 transition-opacity group-hover/associated-project:opacity-100">
                <OptionsDotsIcon />
              </Box>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item icon={<ProjectIcon />}>
                <Link to={TASK_DETAIL_PAGE_PATH.insert({ taskId: task.task_id })} target="_blank">
                  {_t('View "%s"', task.task_name)}
                </Link>
              </Menu.Item>
              <Menu.Item
                icon={<LockIcon />}
                onClick={() => {
                  // TODO: Implement onLock
                }}
              >
                {_t('Close "%s"', task.task_name)}
              </Menu.Item>
              <Menu.Item
                icon={<LockedIcon />}
                onClick={() => {
                  // TODO: Implement onUnlock
                }}
              >
                {_t('Open "%s"', task.task_name)}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
      {task.subtasks
        ?.filter(({ deleted_at, minutes_total }) => !deleted_at || minutes_total > 0)
        ?.map((subtask) => (
          <AssociatedTask key={subtask.task_id} task={subtask} data={data} updateData={updateData} inner />
        ))}
    </Stack>
  );
}
