import { _t } from 'lang';
import { ADD_JOB_POSITION_PAGE_PATH } from 'routes/paths';
import PriceListItemRow from 'components/forms/price-list-form/price-list-items/PriceListItemRow';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { isEmpty } from 'lodash';
import { Box, Group, Stack } from '@mantine/core';
import LinkWithConfirm from 'components/navigate/LinkWithConfirm';
import AddIcon from 'components/icons/AddIcon';
import { useEffect, useState } from 'react';

/**
 * @typedef {{
 *   positionInCompanyId: string;
 *   positionName: string;
 *   projectBasedRate: number;
 *   flatFeeRate: number;
 *   currency: string;
 *   isCustom: boolean;
 * }} PriceListItem
 */

// TODO code review

/**
 * The price list items definition.
 *
 * @param {{
 *   value: any,
 *   onChange: (value: any) => void,
 *   isDefaultPriceList: boolean,
 *   currency?: string
 * }}
 */
export default function PriceListItemsDefinition({ value, onChange, currency, isDefaultPriceList }) {
  const { getAction } = useApi();
  const [loading, setLoading] = useState(false);
  const [positionsInCompany, setPositionsInCompany] = useState([]);
  const [defaultPriceList, setDefaultPriceList] = useState(null);

  useEffect(() => {
    const positionInCompanyGetListAction = getAction('PositionInCompanyGetListAction');
    const priceListDefaultGetAction = getAction('PriceListDefaultGetAction');

    setLoading(true);

    Promise.all([
      positionInCompanyGetListAction({ query: { filter: { 'is_active.eq': 1 } } }).then(setPositionsInCompany),
      priceListDefaultGetAction().then(setDefaultPriceList),
    ])
      .catch(panic)
      .finally(() => {
        setLoading(false);
      });
  }, [getAction]);

  if (loading) {
    return <></>;
  }

  /**
   * Handles change of currency/hourly rate
   */
  const handleChange = (newVal, positionInCompanyId, key) => {
    // TODO when changing from isCustom === true back to false, what should happen?
    // should the value change to zero or should it become the value from the default price list?

    // update the value
    value[positionInCompanyId][key] = newVal;

    // send the updated value to the use-form hook
    onChange(value);
  };

  const gridLayout = isDefaultPriceList ? 'grid-cols-[1fr_112px_140px]' : 'grid-cols-[1fr_140px_128px]';

  if (isEmpty(positionsInCompany)) {
    return (
      <Stack spacing={16} pl={40} pr={48} pb={24}>
        <Box style={{ fontSize: '15px', lineHeight: '20px', color: '#4D4D4D' }}>
          {_t('No job positions available!')}
        </Box>
        <Group style={{ color: '#38298B', fontSize: '15px', lineHeight: '20px', textDecorationLine: 'underline' }}>
          <LinkWithConfirm
            confirmMessage={
              _t('You will be redirected to the job positions page. The data you have inputted will be lost. Are you sure you want to continue?') // prettier-ignore
            }
            confirmTitle={_t('Add job position')}
            to={ADD_JOB_POSITION_PAGE_PATH.original}
          >
            <Group spacing={4}>
              <AddIcon stroke="#38298B" />
              {_t('Add job position')}
            </Group>
          </LinkWithConfirm>
        </Group>
      </Stack>
    );
  }

  return (
    <Box py={16}>
      <Box pl={40} pr={48} className={`grid gap-2 text-[12px] leading-[18px] text-grey-500 ${gridLayout}`}>
        <div>{_t('Job position')}</div>
        {isDefaultPriceList ? <div>{_t('Availability in CE')}</div> : <></>}
        <div className="text-right">{_t('Hour rate')}</div>
        {!isDefaultPriceList ? <div className="text-right">{_t('Set Custom rate')}</div> : <></>}
      </Box>
      {positionsInCompany.map((positionInCompany) => (
        <PriceListItemRow
          key={positionInCompany.position_in_company_id}
          positionInCompanyId={positionInCompany.position_in_company_id}
          positionName={positionInCompany.position_name}
          projectBasedRate={value[positionInCompany.position_in_company_id]['projectBasedRate']}
          handleChange={handleChange}
          currency={currency}
          isDefaultPriceList={isDefaultPriceList}
          isCustom={value[positionInCompany.position_in_company_id]['isCustom']}
          defaultProjectBasedRate={
            defaultPriceList.price_list_items.find(
              (item) => item.position_in_company_id === positionInCompany.position_in_company_id
            )?.project_based_rate || 0
          }
          availableInCostEstimate={positionInCompany.is_for_sale}
        />
      ))}
    </Box>
  );
}
