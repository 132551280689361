/** @typedef {import('api/actions/cost-estimate-get-action/cost-estimate-get-action-response').CostEstimateGetActionResponse['external_costs'][number]} ExternalCost */

import { Collapse, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import CollapseArrow from 'components/CollapseArrow';
import EditIcon from 'components/icons/EditIcon';
import ExternalInvoiceIcon from 'components/icons/ExternalInvoiceIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import { _t } from 'lang';
import { useEffect, useMemo, useState } from 'react';
import TabDetailRow from 'pages/finance/cost-estimate/detail/TabDetailRow';
import PriceDisplay from 'components/PriceDisplay';
import FileDownloadLink from 'components/files/FileDownloadLink';
import { useApi } from 'api/ApiContext';
import { useSearchParams } from 'react-router-dom';

/**
 * One collapsible row with header in the external costs tab.
 *
 * @param {{externalCost: ExternalCost}}
 */
export default function ExternalCostTabRow({ externalCost }) {
  const { workspaceConfig } = useApi();

  const [searchParams] = useSearchParams();
  const queryExternalCostId = useMemo(() => searchParams.get('externalCostId') || null, [searchParams]);
  const isTarget = useMemo(
    () => queryExternalCostId === String(externalCost.external_cost_id),
    [queryExternalCostId, externalCost.external_cost_id]
  );

  const [opened, { toggle: toggleOpened, open: openRow, close: closeRow }] = useDisclosure(isTarget);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const menuClasses = isMenuOpened ? 'opacity-100' : 'opacity-0 group-hover:opacity-100';

  useEffect(() => {
    if (
      searchParams.get('externalCostId') &&
      searchParams.get('externalCostId') === String(externalCost.external_cost_id)
    ) {
      openRow();
    } else {
      closeRow();
    }
  }, [searchParams]);

  return (
    <div>
      <div className="group grid h-12 grid-cols-[1fr_1fr_120px_32px] items-center justify-between gap-2 border-b border-b-neutral-100 bg-white px-2  hover:bg-neutral-20">
        {/* header */}
        <div
          className="flex w-full cursor-pointer items-center gap-x-2 py-3 text-[20px] leading-[36px] text-neutral-700"
          onClick={toggleOpened}
        >
          <div className="h-6 w-6">
            <CollapseArrow opened={opened} />
          </div>
          <div className="flex flex-row items-center gap-1 text-[15px] leading-[18px] text-neutral-700">
            <ExternalInvoiceIcon fill="#4D4D4D" />
            <span>{externalCost.external_cost_full_name}</span>
          </div>
        </div>
        <div className="py-3 text-[15px] leading-[18px] text-neutral-700">{externalCost.supplier_name}</div>
        <PriceDisplay
          size="sm"
          value={externalCost.amount}
          currency={externalCost.currency}
          className="text-right text-neutral-700"
        />
        <div className={`justify-en flex py-3 ${menuClasses}`}>
          <Menu className="hidden" width={220} position="bottom-end" offset={15} onChange={setIsMenuOpened}>
            <Menu.Target>
              <div className="-my-2 cursor-pointer py-2">
                <OptionsDotsIcon />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item icon={<EditIcon fill="#4D4D4D" />}>{_t('Edit / view')}</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>

      {/* content */}
      <Collapse in={opened} className="border-b border-b-neutral-100 bg-white py-4 pl-[68px]">
        <div className="flex flex-col gap-y-2">
          <TabDetailRow title={_t('Supplier')} value={externalCost.supplier_name} />
          <TabDetailRow
            title={_t('Invoice')}
            value={
              <FileDownloadLink h={18} fileId={externalCost.file_id}>
                {externalCost.external_cost_name}
              </FileDownloadLink>
            }
          />
          <TabDetailRow
            title={_t('Date of supply')}
            value={new Date(externalCost.invoice_supplied).toLocaleDateString()}
          />
          <TabDetailRow
            title={_t('Payment due date')}
            value={new Date(externalCost.invoice_due_date).toLocaleDateString()}
          />
          <TabDetailRow
            title={_t('Total paid amount without VAT')}
            value={<PriceDisplay size="md" value={externalCost.amount} currency={externalCost.currency} />}
          />
          <TabDetailRow
            title={_t('Calculated expense')}
            value={
              <PriceDisplay
                size="md"
                value={externalCost.amount / externalCost.currency_rate}
                currency={workspaceConfig.currency}
              />
            }
          />
          <TabDetailRow
            title={_t('Actual amount paid')}
            value={<PriceDisplay size="md" value={externalCost.base_amount} currency={workspaceConfig.currency} />}
          />
          <TabDetailRow title={_t('Invoice subject')} value={externalCost.invoice_subject} />
          <TabDetailRow title={_t('Internal note')} value={externalCost.note ? externalCost.note : '-'} />
          <TabDetailRow title={_t('Processed by')} value={externalCost.full_name} />
          <TabDetailRow title={_t('Date of processing')} value={new Date(externalCost.created_at).toLocaleString()} />
        </div>
      </Collapse>
    </div>
  );
}
