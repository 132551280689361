/** @typedef {import('api/actions/price-list-get-list-action/price-list-get-list-action-response').PriceListGetListActionResponse[number]} PriceList */

import { _t } from 'lang';
import DataTable from 'components/tables/DataTable';
import PriceListStatusSwitch from 'components/inputs/status-switch/PriceListStatusSwitch';
import { useApi } from 'api/ApiContext';
import { EDIT_PRICE_LIST_PAGE_PATH } from 'routes/paths';
import PricelistIcon from 'components/icons/PricelistIcon';
import { Center } from '@mantine/core';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

/**
 * A row in the Price Lists table.
 *
 * @param {{
 *   data: PriceList;
 *   hover: boolean;
 * }}
 */
function PriceListTableRow({ data: priceList, hover }) {
  return (
    <>
      <div className="flex gap-[33px]">
        <PricelistIcon className="scale-[1.65]" height={46} width={36} fill="#38298B" />
        <Center>
          <div className="text-[20px] leading-[20px]">
            <Link to={EDIT_PRICE_LIST_PAGE_PATH.insert({ priceListId: priceList.price_list_id })}>
              <div className="flex items-center transition-all hover:text-main-primary">
                {priceList.price_list_name}
                {hover && (
                  <small className="ml-[10px] text-[12px] text-grey-500">(ID: {priceList.price_list_id})</small>
                )}
              </div>
            </Link>
            <span className="pt-[5px] text-[14px] text-grey-500">
              {priceList.is_default ? _t('Default price list') : _t('Non-default price list')}
            </span>
          </div>
        </Center>
      </div>
      <div>{priceList.currency}</div>
      <div>
        {priceList.clients.length > 0 ? priceList.clients.map(({ client_name }) => client_name).join(', ') : '-'}
      </div>
      {!priceList.is_default ? <PriceListStatusSwitch priceList={priceList} /> : <div></div>}
    </>
  );
}

/**
 * The table in the Price Lists page.
 */
export default function PriceListTable() {
  const { getAction } = useApi();
  const priceListGetListAction = getAction('PriceListGetListAction');

  const columns = useMemo(
    () => [
      { title: _t('Name'), width: 5 },
      { title: _t('Currency'), width: 1 },
      { title: _t('Assigned to'), width: 3 },
      { title: _t('Status'), width: 1 },
    ],
    []
  );

  return (
    <DataTable
      action={priceListGetListAction}
      columns={columns}
      rowKey={(priceList) => priceList.price_list_id}
      editLink={(priceList) => EDIT_PRICE_LIST_PAGE_PATH.insert({ priceListId: priceList.price_list_id })}
      RowComponent={PriceListTableRow}
    />
  );
}
