import { Box } from '@mantine/core';

/**
 * The title of a section in the form.
 *
 * @param {{title: string}}
 */
export default function SectionTitle({ title }) {
  return (
    <Box fz={20} lh={1.8} pt={16} color="neutral700">
      {title}
    </Box>
  );
}
