import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { _t } from 'lang';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CLIENTS_PAGE_PATH } from 'routes/paths';
import {
  BAD_REQUEST_ERROR_CODE,
  DUPLICATE_CLIENT_SHORTCUT_SLUG_ERROR_CODE,
  SUCCESS_NOTIFICATION_COLOR,
} from 'utils/constants';
import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import { nanoid } from 'nanoid';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import ClientForm from 'components/forms/client-form/ClientForm';
import panic from 'errors/Panic';
import { isEmpty } from 'lodash';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';

/**
 * The content of the EditClientPage.
 *
 * @param {{ clientId: string }}
 */
function EditClientImpl({ clientId }) {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { refreshClients } = useSettingsSidebar();

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);
  const [hasShortcutSlugError, setHasShortcutSlugError] = useState(false);
  const [hasNextProjectNumError, setHasNextProjectNumError] = useState(false);

  useEffect(() => {
    const clientGetListAction = getAction('ClientGetListAction');

    setLoading(true);

    clientGetListAction({
      query: {
        filter: { 'client_id.eq': clientId },
        limit: 1,
      },
    })
      .then((data) => {
        if (data.length === 0) {
          setInitialValues({});
          return;
        }

        const client = data[0];

        const rawMembers = client.members.map((member) => String(member.user_id));

        const initialValues = {
          name: client.client_name,
          officialName: client.official_name,
          ico: client.ico,
          dic: client.dic,
          icDph: client.ic_dph || '',
          streetAndNumber: client.street_and_number,
          city: client.city,
          zipCode: client.zip_code,
          country: client.country,
          avatar: client.avatar,
          contactPerson: client.contact_persons.map(({ email, full_name, phone }) => ({
            uuid: nanoid(),
            name: full_name,
            email,
            phone,
          })),
          language: client.language,
          priceLists: client.price_lists.map((priceList) => String(priceList.price_list_id)),
          currency: client.currency,
          increasingPrice: client.increasing_price,
          addPoToInvoice: client.add_po_to_invoice,
          note: client.note,
          noteCostEstimate: client.note_cost_estimate,
          noteInvoice: client.note_invoice,
          members: rawMembers,
          clientId: client.client_id,
          shortcutSlug: client.shortcut_slug,
          shortcutInternal: client.shortcut_internal,
          nextProjectNum: client.next_project_num,
          maxProjectNum: client.max_project_num,
        };

        setInitialValues(initialValues);
      })
      .catch(panic)
      .finally(() => {
        setLoading(false);
      });
  }, [getAction, clientId]);

  if (!clientId || (!loading && isEmpty(initialValues))) {
    return <NotFoundPageContent />;
  }

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({
    name,
    officialName,
    ico,
    dic,
    icDph,
    streetAndNumber,
    city,
    zipCode,
    country,
    avatar,
    contactPerson,
    language,
    priceLists,
    currency,
    increasingPrice,
    addPoToInvoice,
    note,
    noteCostEstimate,
    noteInvoice,
    members,
    shortcutSlug,
    shortcutInternal,
    nextProjectNum,
  }) => {
    const clientUpdateAction = getAction('ClientUpdateAction');

    return clientUpdateAction({
      parameters: { client_id: clientId },
      body: {
        client_name: name,
        official_name: officialName,
        ico,
        dic,
        ic_dph: icDph,
        street_and_number: streetAndNumber,
        city: city,
        zip_code: zipCode,
        country: country,
        currency,
        avatar,
        contact_persons: contactPerson.map(({ name, email, phone }) => ({ full_name: name, email, phone })),
        language,
        price_lists: priceLists.map(Number),
        increasing_price: increasingPrice,
        add_po_to_invoice: addPoToInvoice,
        note,
        note_cost_estimate: noteCostEstimate,
        note_invoice: noteInvoice,
        members,
        shortcut_slug: shortcutSlug,
        shortcut_internal: shortcutInternal,
        next_project_num: nextProjectNum,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Client updated'),
          message: _t("Client '%s' has been updated successfully.", name),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        refreshClients();
        setHasShortcutSlugError(false);
        setHasNextProjectNumError(false);
        setInitialValues((prev) => ({ ...prev, nextProjectNum })); // trick so that the modal doesn't pop up after submission
      })
      .catch((e) => {
        if (e.code === DUPLICATE_CLIENT_SHORTCUT_SLUG_ERROR_CODE) {
          setHasShortcutSlugError(true);
        } else if (e.code === BAD_REQUEST_ERROR_CODE) {
          // this should never happen - unless the user somehow bypasses the frontend validation
          setHasNextProjectNumError(true);
        } else {
          panic(e);

          navigate(CLIENTS_PAGE_PATH.original);
        }
      });
  };

  return (
    <>
      <PageHeading heading={_t('Edit client')} />
      {!loading && (
        <ClientForm
          initialValues={initialValues}
          onSubmit={submit}
          hasShortcutSlugError={hasShortcutSlugError}
          hasNextProjectNumError={hasNextProjectNumError}
        />
      )}
    </>
  );
}

/**
 * Displays a form to add a client.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=522%3A24642
 */
export default function EditClientPage() {
  const { clientId } = useParams();

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <EditClientImpl key={clientId} clientId={clientId} />
      </div>
    </DashboardLayout>
  );
}
