/** @typedef {import('@tiptap/react').Editor} Editor */

import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import SubScript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LoadingOverlay } from '@mantine/core';
import panic from 'errors/Panic';
import { showNotification } from '@mantine/notifications';
import { _t } from 'lang';
import WysiwygEditorToolbar from 'components/inputs/wysiwyg/WysiwygEditorToolbar';
import { useToolioImage } from 'components/inputs/wysiwyg/extensions/ToolioImageContext';
import { ToolioImageProvider } from 'components/inputs/wysiwyg/extensions/ToolioImageProvider';
import { IMAGE_UPLOAD_MAX_SIZE } from 'environment';
import { WARNING_NOTIFICATION_COLOR } from 'utils/constants';
import formatFileSize from 'utils/format-file-size';
import { Mention } from '@tiptap/extension-mention';
import suggestion from 'components/inputs/wysiwyg/extensions/mention-list/suggestion';
import { useApi } from '../../../api/ApiContext';
import { MentionListProvider } from './extensions/mention-list/MentionList';
import fullTextSearch from 'utils/full-text-search';
import Preloader from 'components/Preloader';
import { debounce } from 'lodash';
import { CtrlEnterSubmit } from './extensions/CtrlEnterSubmit';

/**
 * Parameters of the WysiwygEditor component.
 *
 * @typedef {React.Ref<{
 *   getHTML: () => string,
 *   getText: () => string,
 *   isEmpty: () => boolean,
 *   isDirty: () => boolean,
 *   focus: () => void,
 * }>} EditorRef
 *
 * @typedef {{
 *   editorRef?: EditorRef,
 *   clientId?: number,
 *   permissionSlug?: string,
 *   initialContent?: string,
 *   onUserMentioned?: (userId: number) => void,
 *   autofocus?: boolean | 'start' | 'end' | 'all' | number | null,
 *   onChange?: (html: string) => void,
 *   debounceWait?: number,
 * }} WysiwygEditorProps
 */

/**
 * A wysiwyg editor (implementation).
 *
 * @param {Omit<WysiwygEditorProps, 'onUserMentioned'>}
 */
function WysiwygEditorImpl({
  editorRef,
  clientId,
  permissionSlug,
  autofocus = false,
  initialContent = '',
  onChange,
  debounceWait = 500,
}) {
  const contentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { ToolioImage, createToolioImage, loading: pastingImage, resizing: resizingImage } = useToolioImage();
  const { getAction } = useApi();
  const users = useRef([]);
  const hadFocus = useRef(false);

  const sx = useMemo(
    () => ({ '& .prosemirror-dropcursor-block': { display: resizingImage ? 'none' : undefined } }),
    [resizingImage]
  );

  useEffect(() => {
    const userGetListAction = getAction('UserGetListAction');

    const filter = { 'is_active.eq': 1 };

    if (clientId && permissionSlug) {
      filter['permission.has'] = [{ client_id: clientId, permission_slug: permissionSlug }];
    }

    userGetListAction({ query: { filter } })
      .then((fetchedUsers) => (users.current = fetchedUsers))
      .catch(panic);
  }, [clientId, permissionSlug]);

  const onChangeImpl = useCallback(
    ({ editor }) => {
      if (onChange && editor) {
        onChange(editor.getHTML());
      }
    },
    [onChange]
  );

  const onChangeDebounced = useMemo(() => debounce(onChangeImpl, debounceWait), [onChangeImpl, debounceWait]);

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Highlight,
        ToolioImage.configure({ allowBase64: true }),
        Link.configure({ linkOnPaste: true }),
        SubScript,
        Superscript,
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
        Underline,
        Mention.configure({
          HTMLAttributes: {
            class: 'pl-2 py-1 pr-1 bg-neutral-100 rounded-lg text-nowrap',
          },
          suggestion: {
            items: ({ query }) => users.current.filter((item) => fullTextSearch(query, item.full_name)).slice(0, 5),
            render: suggestion.render,
          },
        }),
        CtrlEnterSubmit,
      ],
      autofocus,
      content: initialContent,
      editorProps: {
        handleDrop: (view, event) => {
          hadFocus.current = true;

          const { files } = event.dataTransfer;
          const images = [];

          for (let i = 0; i < files.length; i++) {
            const file = files.item(i);

            if (!file.type.match(/^image\//)) {
              showNotification({
                color: WARNING_NOTIFICATION_COLOR,
                title: _t('Only images are allowed'),
                message: _t('Image %s cannot be inserted in the text. If you need to upload this file, please use the "Upload file" button below.', file.name), // prettier-ignore
                autoClose: 10000,
              });
            } else if (file.size > IMAGE_UPLOAD_MAX_SIZE) {
              showNotification({
                color: WARNING_NOTIFICATION_COLOR,
                title: _t('Image is too large'),
                message: _t('Images larger than %s must be uploaded as attachments.', formatFileSize(IMAGE_UPLOAD_MAX_SIZE)), // prettier-ignore
                autoClose: 10000,
              });
            } else {
              images.push(file);
            }
          }

          if (images.length > 0) {
            setLoading(true);

            (async () => {
              try {
                for (const image of images) {
                  const node = await createToolioImage(view, image);
                  const { pos } = view.posAtCoords({ left: event.clientX, top: event.clientY });
                  const tx = view.state.tr.insert(pos, node);
                  view.dispatch(tx);
                }
              } catch (error) {
                panic(error);
              } finally {
                setLoading(false);
              }
            })();
          }

          return files.length > 0;
        },
        attributes: {
          class: 'min-h-[125px] max-h-[50vh] h-full overflow-auto',
        },
      },
      onFocus: () => (hadFocus.current = true),
      onUpdate: onChangeDebounced,
    },
    []
  );

  // Set editorRef
  useEffect(() => {
    if (editorRef) {
      editorRef.current = {
        getHTML: () => editor.getHTML(),
        getText: () => editor.getText(),
        isEmpty: () => editor.isEmpty,
        isDirty: () => hadFocus.current,
        focus: () => editor?.view?.focus(),
        getMentioned: () => {
          const mentioned = new Set();

          editor.view.state.doc.descendants((node) => {
            if (node.type.name === 'mention') {
              mentioned.add(node.attrs.id);
            }
          });

          return Array.from(mentioned);
        },
      };
    }

    return () => {
      if (editorRef) {
        editorRef.current = null;
      }
    };
  }, [editor, editorRef]);

  return (
    <RichTextEditor sx={sx} withTypographyStyles editor={editor}>
      <LoadingOverlay zIndex={5} visible={loading || pastingImage} loader={<Preloader size={32} />} />
      <WysiwygEditorToolbar />
      <RichTextEditor.Content ref={contentRef} />
    </RichTextEditor>
  );
}

/**
 * A wysiwyg editor.
 *
 * @param {WysiwygEditorProps} props
 */
export default function WysiwygEditor({ onUserMentioned, onSubmit, ...props }) {
  return (
    <ToolioImageProvider onSubmit={onSubmit}>
      <MentionListProvider onUserMentioned={onUserMentioned}>
        <WysiwygEditorImpl {...props} />
      </MentionListProvider>
    </ToolioImageProvider>
  );
}
