/** @typedef {import('pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData').CostEstimateRowData} CostEstimateRowData */
/** @typedef {import('pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateSectionData').CostEstimateSectionData} CostEstimateSectionData */

import { useEffect, useRef, useState } from 'react';
import { _t } from 'lang';
import { Button, Center, Collapse, Flex, Menu, Stack } from '@mantine/core';
import { nanoid } from 'nanoid';
import CostEstimateRow from 'pages/finance/cost-estimate/add-cost-estimate/components/row/CostEstimateRow';
import CollapseArrow from 'components/CollapseArrow';
import { createCostEstimateSectionData } from 'pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateSectionData';
import DiscountForm from 'pages/finance/cost-estimate/add-cost-estimate/components/discount/DiscountForm';
import DeleteIcon from 'components/icons/DeleteIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import DuplicateIcon from 'components/icons/DuplicateIcon';
import SectionName from 'pages/finance/cost-estimate/add-cost-estimate/components/section/SectionName';
import PriceDisplay from 'components/PriceDisplay';
import AgencyFeeForm from 'pages/finance/cost-estimate/add-cost-estimate/components/agency-fee/AgencyFeeForm';
import { useClient } from 'providers/client/ClientProvider';
import useImmutable from 'hooks/use-immutable';
import { useDisclosure } from '@mantine/hooks';
import { useData } from '../../providers/DataProvider';
import BillingTypeLabel from '../../../../../../components/BillingTypeLabel';
import DragIcon from '../../../../../../components/icons/DragIcon';
import { useDrag, useDrop } from 'react-dnd';
import AddRowIcon from '../../../../../../components/icons/AddRowIcon';
import DiscountIcon from '../../../../../../components/icons/DiscountIcon';
import PrefilledIcon from '../../../../../../components/icons/PrefilledIcon';
import InsertFromAboveIcon from '../../../../../../components/icons/InsertFromAboveIcon';
import { useJobSelect } from '../../providers/JobSelectProvider';
import AttachmentIcon from '../../../../../../components/icons/AttachmentIcon';
import AgencyFeeIcon from '../../../../../../components/icons/AgencyFeeIcon';
import { IconPencil } from '@tabler/icons';
import { useCostEstimatePartData } from '../part/CostEstimatePart';
import { uniq } from 'lodash';
import panic from '../../../../../../errors/Panic';
import { useApi } from '../../../../../../api/ApiContext';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import { CUSTOM_JOB_POSITION_ITEM_ID } from 'pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData';

/**
 * CostEstimateSection component
 *
 * @param {{
 *   id: string,
 *   isInHouse: boolean,
 *   onChange: (data: CostEstimateSectionData) => void,
 *   onDelete: () => void;
 *   onDuplicate: (value: CostEstimateSectionData) => void;
 *   initialData: Partial<CostEstimateSectionData>,
 *   autoFocus?: boolean,
 * }}
 */
export default function CostEstimateSection({
  id,
  isInHouse,
  onChange,
  onDelete,
  onDuplicate,
  initialData,
  autoFocus = false,
  swapSections,
}) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [opened, { toggle: toggleOpened }] = useDisclosure(true);
  const [hasPriceModifier, setHasPriceModifier] = useState(!!initialData?.discount || !!initialData?.agencyFee);
  const [rows, setRows] = useState(initialData.rows.map((data) => createRow(data)));
  const [data, updateData] = useImmutable(initialData, { createFn: createCostEstimateSectionData });
  const { client } = useClient();
  const { data: costEstimateData } = useData();
  const { jobData, hasJobPosition } = useJobSelect();
  const { data: costEstimatePartData } = useCostEstimatePartData(); // eslint-disable-line no-unused-vars
  const { getAction } = useApi();
  const { confirm } = useConfirm();

  /**
   * Handles row change.
   *
   * @param {string} id
   * @param {CostEstimateRowData} data
   */
  function onRowChange(id, data) {
    setRows((rows) => rows.map((row) => (row.id === id ? { ...row, data } : row)));
  }

  /**
   * Deletes a row.
   *
   * @param {string} id
   */
  function deleteRow(id) {
    setRows((rows) => rows.filter((row) => row.id !== id));
  }

  /**
   * Swaps two rows.
   *
   * @param {string} id1
   * @param {string} id2
   */
  function swapRows(id1, id2) {
    setRows((rows) => {
      // Copy the rows to avoid mutating the state.
      const newRows = [...rows];

      const i = newRows.findIndex((row) => row.id === id1);
      const j = newRows.findIndex((row) => row.id === id2);

      // Swap the rows.
      [newRows[i], newRows[j]] = [newRows[j], newRows[i]];

      return newRows;
    });
  }

  /**
   * Creates a row and returns it.
   *
   * @param {CostEstimateRowData} [data]
   */
  function createRow(data) {
    const rowId = nanoid();

    const component = (
      <CostEstimateRow
        key={rowId}
        id={rowId}
        sectionId={id}
        initialData={data}
        isInHouse={isInHouse}
        onDelete={() => deleteRow(rowId)}
        onChange={(data) => onRowChange(rowId, data)}
        onDuplicate={(data) => addRow(data)} // The declaration is hoisted, so this is fine.
        swapRows={swapRows}
      />
    );

    return { id: rowId, component, data };
  }

  /**
   * Creates a row and adds it to the rows state.
   *
   * @param {CostEstimateRowData} [data]
   */
  function addRow(data) {
    setRows((rows) => [...rows, createRow(data)]);
  }

  /**
   * Replaces all rows with job positions. One row per job position.
   */
  function addAllJobPositionRows() {
    setRows(() =>
      jobData
        .filter(({ value }) => value !== CUSTOM_JOB_POSITION_ITEM_ID)
        .map(({ label, value }) => createRow({ jobPositionName: label, jobPositionId: value }))
    );
  }

  /**
   * Prompts the user to confirm their intention to add all job positions.
   */
  function confirmAddAllJobPositionRows() {
    confirm({
      title: _t('Add all job positions'),
      message: _t('Are you sure you want to add all job positions? Current data will be replaced.'),
      onConfirm: addAllJobPositionRows,
    });
  }

  /**
   * Loads data from time entries and updates the rows with the summary of internal costs.
   * It retrieves tasks and projects from the cost estimate data, processes them
   * to get the internal cost summary, and replaces the current rows with the new ones.
   */
  function loadFromTimeEntries() {
    const tasks = uniq(costEstimatePartData.projects.filter(({ allTasks }) => !allTasks).flatMap(({ tasks }) => tasks));
    const projects = costEstimatePartData.projects.filter(({ allTasks }) => allTasks).map(({ projectId }) => projectId);

    const getSummary = getAction('TimeLogGetInternalCostsSummaryAction');

    getSummary({ query: { tasks, projects } })
      .then(({ groups }) =>
        setRows(() =>
          groups
            .filter(({ position_in_company_id }) => hasJobPosition(position_in_company_id))
            .map(({ position_in_company_id, position_name, total_hours }) =>
              createRow({
                jobPositionName: position_name,
                jobPositionId: position_in_company_id,
                number: total_hours,
              })
            )
        )
      )
      .catch(panic);
  }

  /**
   * Prompts the user to confirm their intention to load data from time entries.
   */
  function confirmLoadFromTimeEntries() {
    confirm({
      title: _t('Load from time entries'),
      message: _t('Are you sure you want to load data from time entries? Current data will be replaced.'),
      onConfirm: loadFromTimeEntries,
    });
  }

  /**
   * Removes the price modifier.
   */
  function removePriceModifier() {
    setHasPriceModifier(false);
    updateData({ discount: undefined, agencyFee: undefined });
  }

  // Compute data.
  useEffect(() => {
    updateData({
      rows: rows.map(({ data }) => data).filter(Boolean),
    });
  }, [rows]);

  // Propagate data.
  useEffect(() => {
    onChange(data);
  }, [data]);

  // Drag & Drop
  const dragRef = useRef(null);
  const previewRef = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'cost-estimate-section',
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item) => {
      const dragId = item.id;
      const hoverId = id;

      // Don't replace items with themselves
      if (dragId === hoverId) {
        return;
      }

      swapSections(dragId, hoverId);
    },
  });

  const [{ opacity }, drag, preview] = useDrag(() => ({
    type: 'cost-estimate-section',
    item: () => ({ id }),
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
  }));

  drag(dragRef);
  drop(preview(previewRef));

  return (
    <div
      className="flex flex-col rounded-2xl bg-white"
      ref={previewRef}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      {/* Header */}
      <div
        className={`${
          isInHouse ? 'bg-main-secondary' : 'bg-[#F3E5DF]'
        } group grid w-full grid-cols-[24px_24px_1fr_168px_128px_64px] items-center gap-x-2 px-2 py-4`}
      >
        <div ref={dragRef} className="flex cursor-grab items-center justify-center opacity-0 group-hover:opacity-100">
          <DragIcon />
        </div>
        <div className="flex cursor-pointer items-center justify-center" onClick={toggleOpened}>
          <CollapseArrow opened={opened} />
        </div>
        <SectionName data={data} updateData={updateData} autoFocus={autoFocus} />
        <span />
        <div className="flex items-center justify-end">
          <BillingTypeLabel billingType={isInHouse ? 'inhouse' : 'outofhouse'} />
        </div>
        <div className="flex items-stretch justify-end pr-2">
          <div className={isMenuOpened ? 'flex' : 'hidden group-hover:flex'}>
            <Menu width={200} offset={15} onChange={setIsMenuOpened}>
              <Menu.Target>
                <div className="flex cursor-pointer items-center justify-center">
                  <OptionsDotsIcon />
                </div>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  onClick={() => {
                    // TODO: Implement edit section title
                  }}
                  icon={<IconPencil />}
                >
                  <span>{_t('Edit section title')}</span>
                </Menu.Item>
                <Menu.Item onClick={() => onDuplicate(data)} icon={<DuplicateIcon />}>
                  <span>{_t('Duplicate section')}</span>
                </Menu.Item>
                <Menu.Item onClick={onDelete} icon={<DeleteIcon stroke={'#BF0D38'} />}>
                  <span className="text-complementary-danger">{_t('Delete section')}</span>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
      </div>

      <Collapse in={opened}>
        {/* Table */}
        {isInHouse ? (
          <div className="grid grid-cols-[264px_1fr_80px_80px_110px_140px_128px_24px_24px] gap-x-[8px] pl-[40px] pr-2 pt-4 text-base text-grey-500">
            <span className="px-2 text-[12px] leading-[18px]">{_t('Description')}</span>
            <span className="px-2 text-[12px] leading-[18px]">{_t('Note')}</span>
            <span className="px-2 text-[12px] leading-[18px]">{_t('Unit')}</span>
            <span className="px-2 text-right text-[12px] leading-[18px]">{_t('Number')}</span>
            <span className="px-2 text-right text-[12px] leading-[18px]">{_t('Unit price')}</span>
            <span className="px-2 text-right text-[12px] leading-[18px]">{_t('Price')}</span>
            <span className="px-2 text-right text-[12px] leading-[18px]">{_t('External costs')}</span>
          </div>
        ) : (
          <div className="grid grid-cols-[1fr_140px_128px_24px_24px] gap-x-[8px] pl-[40px] pr-2 pt-4 text-base text-grey-500">
            <span className="px-2 text-[12px] leading-[18px]">{_t('Description')}</span>
            <span className="px-2 text-right text-[12px] leading-[18px]">{_t("Supplier's price")}</span>
            <span className="px-2 text-right text-[12px] leading-[18px]">{_t('External price')}</span>
          </div>
        )}
        {rows.map(({ component }) => component)}

        {/* Buttons */}
        <div className="flex gap-x-[32px] py-[16px] pl-[40px]">
          <Button
            className="flex h-[24px] items-center border-0 p-0 text-primary-dark-blue"
            onClick={addRow}
            variant="link"
          >
            <Center w={24} h={24}>
              <AddRowIcon width={18} height={18} stroke="#38298B" />
            </Center>
            <span className="ml-[4px] text-[15px] leading-[20px]">{_t('Add row')}</span>
          </Button>
          <Button
            className="flex h-[24px] items-center border-0 p-0 text-primary-dark-blue"
            onClick={confirmAddAllJobPositionRows}
            variant="link"
          >
            <PrefilledIcon width={24} height={24} stroke="#38298B" />
            <span className="ml-[4px] text-[15px] leading-[20px]">{_t('Add all job positions')}</span>
          </Button>
          <Button
            className="flex h-[24px] items-center border-0 p-0 text-primary-dark-blue"
            onClick={confirmLoadFromTimeEntries}
            variant="link"
          >
            <InsertFromAboveIcon width={24} height={24} stroke="#38298B" />
            <span className="ml-[4px] text-[15px] leading-[20px]">{_t('Load from time entries')}</span>
          </Button>
          {/* TODO: Add all job positions */}
          {/* jobData.forEach(position => addRow({ jobPositionId: position.id })) -- "check for real property names" */}
          {/* See src/pages/finance/cost-estimate/add-cost-estimate/providers/JobSelectProvider.jsx */}
        </div>

        {/* Total */}
        <div className="group flex flex-row items-end justify-between gap-y-4 border-t-[1px] border-neutral-300 py-6 pl-[40px] pr-2">
          <Flex gap={32}>
            {!hasPriceModifier && (
              <>
                {isInHouse && (
                  <Button
                    className="flex h-[24px] items-center border-0 p-0 text-primary-dark-blue"
                    onClick={() => setHasPriceModifier(true)}
                    variant="link"
                  >
                    <DiscountIcon width={24} height={24} stroke="#38298B" />
                    <span className="ml-[4px] text-[15px] leading-[20px]">{_t('Add discount')}</span>
                  </Button>
                )}

                {!isInHouse && (
                  <Button
                    className="flex h-[24px] items-center border-0 p-0 text-primary-dark-blue"
                    onClick={() => setHasPriceModifier(true)}
                    variant="link"
                  >
                    <AgencyFeeIcon width={24} height={24} stroke="#38298B" />
                    <span className="ml-[4px] text-[15px] leading-[20px]">{_t('Add agency fee')}</span>
                  </Button>
                )}
              </>
            )}
            <Button
              className="flex h-[24px] items-center border-0 p-0 text-primary-dark-blue"
              variant="link"
              disabled={true}
            >
              <AttachmentIcon width={24} height={24} stroke="#38298B" />
              <span className="ml-[4px] text-[15px] leading-[20px]">{_t('Add note and attachments')}</span>
            </Button>
          </Flex>

          <Stack spacing={8} align="flex-end">
            {hasPriceModifier && (
              <>
                <div className="grid h-[24px] grid-cols-[auto_168px_128px_64px] items-center justify-end gap-x-[8px] text-right">
                  <span className="text-[15px] leading-[18px]">{_t('Section subtotal:')}</span>
                  <PriceDisplay currency={costEstimateData.currency} value={data.subtotal} />
                </div>

                {isInHouse ? (
                  <DiscountForm
                    initialData={initialData?.discount}
                    onChange={(discount) => updateData({ discount })}
                    onDelete={removePriceModifier}
                    discountAmount={data.discountAmount}
                    currency={costEstimateData.currency}
                  />
                ) : (
                  <AgencyFeeForm
                    initialData={initialData?.agencyFee || { amount: client.increasing_price }}
                    onChange={(agencyFee) => updateData({ agencyFee })}
                    onDelete={removePriceModifier}
                    feeAmount={data.agencyFeeAmount}
                    currency={costEstimateData.currency}
                  />
                )}
              </>
            )}
            <div className="grid h-[24px] grid-cols-[auto_168px_128px_64px] items-center justify-end gap-x-[8px] text-right">
              <span className="text-[15px] leading-[18px]">{_t('Section total:')}</span>
              <PriceDisplay currency={costEstimateData.currency} size="lg" value={data.total} />
              <PriceDisplay
                currency={costEstimateData.currency}
                className="text-complementary-african-violet"
                value={data.externalCosts}
              />
            </div>
          </Stack>
        </div>
      </Collapse>
    </div>
  );
}
