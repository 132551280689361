import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import { useMemo } from 'react';
import { useData } from '../../providers/DataProvider';
import { _t } from 'lang';
import { Breadcrumbs } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useClient } from 'providers/client/ClientProvider';
import { FINANCE_PROJECT_OVERVIEW_PAGE_PATH } from 'routes/paths';

/**
 * Heading section for cost estimate page.
 */
export default function HeadingSection() {
  const { data } = useData();
  const { client, clientId } = useClient();

  const suffix = useMemo(() => (data.isDraft ? _t('(draft)') : ''), [data.isDraft]);

  const heading = useMemo(
    () => (data.costEstimateId ? `${data.fullName} ${suffix}`.trim() : _t('New Cost Estimate')),
    [data.fullName, suffix]
  );

  const clientFinanceDetailLink = useMemo(
    () => `${FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}?clientId=${clientId}`,
    [clientId]
  );

  return (
    <PageHeading
      heading={heading}
      breadcrumbs={
        <Breadcrumbs>
          <Link to={FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}>{_t('Finance')}</Link>
          {client && <Link to={clientFinanceDetailLink}>{client.client_name}</Link>}
        </Breadcrumbs>
      }
    />
  );
}
