import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { JOB_POSITIONS_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { showNotification } from '@mantine/notifications';
import { useNavigate, useParams } from 'react-router-dom';
import { _t } from 'lang';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import JobPositionForm from 'components/forms/job-position-form/JobPositionForm';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import panic from 'errors/Panic';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';

/**
 * The content of the EditJobPositionPage.
 *
 * @param {{ jobPositionId: string }}
 */
function EditJobPositionImpl({ jobPositionId }) {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { refreshJobPositions } = useSettingsSidebar();

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const positionInCompanyGetListAction = getAction('PositionInCompanyGetListAction');

    setLoading(true);

    positionInCompanyGetListAction({
      query: {
        filter: { 'position_in_company_id.eq': jobPositionId },
        limit: 1,
      },
    })
      .then((data) => {
        if (data.length === 0) {
          setInitialValues({});
          return;
        }

        const jobPosition = data[0];
        const rawMembers = jobPosition.members.map((member) => String(member.user_id));

        const initialValues = {
          positionName: jobPosition.position_name,
          projectBasedRate: jobPosition.project_based_rate,
          flatFeeRate: jobPosition.flat_fee_rate,
          internalCost: jobPosition.internal_cost,
          isForSale: jobPosition.is_for_sale,
          members: rawMembers,
        };

        setInitialValues(initialValues);
      })
      .catch(panic)
      .finally(() => {
        setLoading(false);
      });
  }, [getAction, jobPositionId]);

  if (!jobPositionId || (!loading && isEmpty(initialValues))) {
    return <NotFoundPageContent />;
  }

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ positionName, projectBasedRate, flatFeeRate, internalCost, isForSale }) => {
    const positionInCompanyUpdateAction = getAction('PositionInCompanyUpdateAction');

    return positionInCompanyUpdateAction({
      parameters: { position_in_company_id: jobPositionId },
      body: {
        position_name: positionName,
        project_based_rate: projectBasedRate,
        flat_fee_rate: flatFeeRate,
        internal_cost: internalCost,
        is_for_sale: isForSale,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Job position updated'),
          message: _t("Job position '%s' has been updated successfully.", `${positionName}`),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        refreshJobPositions();
      })
      .catch((e) => {
        panic(e);

        navigate(JOB_POSITIONS_PAGE_PATH.original);
      });
  };

  return (
    <>
      <PageHeading heading={_t('Edit job position')} />
      {!loading && <JobPositionForm initialValues={initialValues} onSubmit={submit} />}
    </>
  );
}

/**
 * Edit Job Position page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=508%3A26263
 */
export default function EditJobPositionPage() {
  const { jobPositionId } = useParams();

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <EditJobPositionImpl key={jobPositionId} jobPositionId={jobPositionId} />
      </div>
    </DashboardLayout>
  );
}
