/** @typedef {import('../CostEstimateData').CostEstimateData} CostEstimateData */

import { Box, TextInput } from '@mantine/core';
import { _t } from 'lang';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useData } from 'pages/finance/cost-estimate/add-cost-estimate/providers/DataProvider';
import FormRow from 'components/forms/FormRow';

/**
 * The name of a cost estimate.
 */
export default function CostEstimateName() {
  const { data, updateData } = useData();
  const [name, setName] = useState(data.name);

  // Debounce can take longer here because change in cost estimate name does not
  // cause changes in the price calculation.
  const [propagateChanges] = useState(() => debounce((name) => updateData({ name }), 100, { maxWait: 500 }));

  // Propagate changes when the name changes.
  useEffect(() => {
    propagateChanges(name);
  }, [name]);

  return (
    <FormRow
      label={_t('Cost estimate name')}
      input={
        <Box className="grid grid-cols-[auto_1fr] items-center gap-3">
          <Box>{data.prefix}</Box>
          <TextInput
            type="text"
            placeholder={_t('Cost estimate name')}
            value={name}
            onChange={({ target: { value: name } }) => setName(name)}
          />
        </Box>
      }
    />
  );
}
