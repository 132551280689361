import { Box, Button, Group, Switch } from '@mantine/core';
import PriceDisplay from 'components/PriceDisplay';
import MoneyInput from 'components/inputs/MoneyInput';
import { _t } from 'lang';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { EDIT_JOB_POSITION_PAGE_PATH } from 'routes/paths';

// TODO code review

/**
 * One row in the PriceListItemsDefinition.
 *
 * @param {{
 *    positionInCompanyId: number,
 *    positionName: string,
 *    projectBasedRate: number,
 *    isCustom: boolean,
 *    handleChange: (newVal: number, positionInCompanyId: number, key: string) => void,
 *    isDefaultPriceList: boolean,
 *    currency?: string,
 *    defaultProjectBasedRate: number,
 *    availableInCostEstimate: boolean,
 *  }}
 */
export default function PriceListItemRow({
  positionInCompanyId,
  positionName,
  projectBasedRate,
  isCustom,
  handleChange,
  isDefaultPriceList,
  currency = 'EUR',
  defaultProjectBasedRate,
  availableInCostEstimate: availableInCE,
}) {
  const [isChecked, setIsChecked] = useState(isDefaultPriceList || isCustom);

  const detailPath = EDIT_JOB_POSITION_PAGE_PATH.insert({ jobPositionId: positionInCompanyId });
  const suffix = `${currency}/ ${_t('h')}`;
  const gridLayout = isDefaultPriceList ? 'grid-cols-[1fr_112px_140px]' : 'grid-cols-[1fr_140px_128px]';

  return (
    <Box
      pt={8}
      pb={8}
      h={56}
      pl={40}
      pr={48}
      className={`grid items-center gap-2 ${gridLayout} border-b border-b-neutral-100`}
      hidden={!availableInCE && !isDefaultPriceList}
    >
      <Group pt={8} pb={8}>
        <Button variant="link" component={Link} to={detailPath} target="_blank">
          {positionName}
        </Button>
      </Group>
      {isDefaultPriceList ? (
        <div className="flex h-6 flex-row items-center gap-1 text-neutral-700">
          <div className={`h-2 w-2 rounded-full ${availableInCE ? 'bg-status-green' : 'bg-status-grey'}`}></div>
          <span className="text-[12px]">{availableInCE ? _t('Available') : _t('Not available')}</span>
        </div>
      ) : (
        <></>
      )}
      {/* Project-based rate */}
      {isChecked ? (
        <div className="flex justify-end">
          <MoneyInput
            maw={200}
            value={availableInCE ? projectBasedRate : 0}
            onChange={(val) => handleChange(val, positionInCompanyId, 'projectBasedRate')}
            currency={suffix}
            disabled={!availableInCE}
          />
        </div>
      ) : (
        <PriceDisplay className="pr-2 text-right" value={defaultProjectBasedRate} currency={suffix} />
      )}
      {!isDefaultPriceList ? (
        <Group position="right">
          <Switch
            checked={isCustom}
            onChange={(event) => {
              setIsChecked(event.currentTarget.checked);
              handleChange(event.currentTarget.checked, positionInCompanyId, 'isCustom');
            }}
          />
        </Group>
      ) : (
        <></>
      )}
    </Box>
  );
}
