/** @typedef {import('api/actions/position-in-company-get-list-action/position-in-company-get-list-action-response').PositionInCompanyGetListActionResponse[number]} JobPosition */

import { _t } from 'lang';
import DataTable from 'components/tables/DataTable';
import JobPositionStatusSwitch from 'components/inputs/status-switch/JobPositionStatusSwitch';
import UserView from 'components/avatars/UserView';
import { useApi } from 'api/ApiContext';
import { EDIT_JOB_POSITION_PAGE_PATH } from 'routes/paths';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

/**
 * A row in the Job Positions table.
 *
 * @param {{
 *   data: JobPosition;
 *   hover: boolean;
 * }}
 */
function JobPositionTableRow({ data: jobPosition, hover }) {
  const detailPath = useMemo(
    () => EDIT_JOB_POSITION_PAGE_PATH.insert({ jobPositionId: jobPosition.position_in_company_id }),
    [jobPosition.position_in_company_id]
  );

  const detailMembersPath = useMemo(() => `${detailPath}?tab=members`, [detailPath]);

  const subtitle = useMemo(() => {
    if (!jobPosition.is_for_sale) {
      return _t('Not available for cost estimates');
    }

    return `${jobPosition.project_based_rate} ${jobPosition.currency} / ${_t('hour')}`;
  }, [jobPosition.currency, jobPosition.is_for_sale, jobPosition.project_based_rate]);

  return (
    <>
      <div className="text-[20px] leading-[20px]">
        <Link to={detailPath} className="block w-fit">
          <div className="flex items-center transition-all hover:text-main-primary">
            {jobPosition.position_name}
            {hover && (
              <small className="ml-[10px] text-[12px] text-grey-500">(ID: {jobPosition.position_in_company_id})</small>
            )}
          </div>
        </Link>
        <span className="pt-[5px] text-[14px] text-grey-500">{subtitle}</span>
      </div>
      <div className="flex">
        <UserView max={9} users={jobPosition.members} editUsersPath={detailMembersPath} />
      </div>
      <JobPositionStatusSwitch jobPosition={jobPosition} />
    </>
  );
}

/**
 * The table in the Teams page.
 */
export default function JobPositionTable() {
  const { getAction } = useApi();
  const positionInCompanyGetListAction = getAction('PositionInCompanyGetListAction');

  const columns = useMemo(
    () => [
      { title: _t('Job position'), width: 5 },
      { title: _t('Members'), width: 3 },
      { title: _t('Status'), width: 1 },
    ],
    []
  );

  return (
    <DataTable
      action={positionInCompanyGetListAction}
      columns={columns}
      rowKey={(jobPosition) => jobPosition.position_in_company_id}
      editLink={(jobPosition) =>
        EDIT_JOB_POSITION_PAGE_PATH.insert({ jobPositionId: jobPosition.position_in_company_id })
      }
      RowComponent={JobPositionTableRow}
    />
  );
}
